import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Topbar from '../components/Topbar';
import Sidebar from "../components/Sidebar";
import Loading from '../../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
// import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import i18n from "i18next";
import { DatePicker, Row, Col, Select } from "antd";
import dayjs from 'dayjs';

const mySwal = withReactContent(Swal);
const { RangePicker } = DatePicker;

class TokenPurchaseHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            toggled: false,
            loading: false,
            startDate: "",
            endDate: "",
            operatorId: "",
            distributorId: "",
            operators: [],
            country: ""
        };
    }

    componentDidMount() {
        if (!this.props.client || !this.props.client.token_report) {
            return this.props.history.push('/client');
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleDate = dates => {
        if (dates) {
            const [start, end] = dates;
            this.setState({
                startDate: start,
                endDate: end
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null
            });
        }
    };

    handleFilter = e => {
        const { startDate, endDate, /*operatorId,*/ distributorId } = this.state;

        if (!distributorId) {
            mySwal.fire(i18n.t("Error"), i18n.t("Please select distributor"), "error");
        }
        // else if (!operatorId) {
        //     mySwal.fire("Error", "Please select the operator", "error");
        // }
        else {
            let sendThis = {
                operatorId: this.props.client.admin.filter(admin => admin.distributorId === distributorId).map(admin => admin.id)
            };

            if (startDate && endDate) {
                sendThis.startDate = new Date(startDate).setHours(0, 0, 0, 0);
                sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
            }
            this.setState({
                ...this.state,
                loading: true
            });
            axios
                .post("/api/admin/report/token/purchaseHistory", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data,
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire(i18n.t("Error"), i18n.t(err.response.data.error), "error");
                });
        }
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            startDate: "",
            endDate: "",
            operatorId: "",
            data: [],
            operators: [],
            distributorId: "",
            country: ""
        });
    };

    handleChangeDistributor = (value) => {
        const selectedOperator = this.props.client.admin.filter(admin => admin.distributorId === value);

        this.setState({
            operators: selectedOperator,
            distributorId: value,
            operatorId: "",
            country: this.props.client.distributor.find(distributor => distributor.id === value)?.country || ""
        });
    };

    render() {
        let tableData = {
            columns: [
                {
                    label: i18n.t("ID"),
                    field: "id",
                    sort: "asc"
                },
                {
                    label: i18n.t("User Name"),
                    field: "name",
                    sort: "asc"
                },
                {
                    label: i18n.t("Phone Number"),
                    field: "number",
                    sort: "asc"
                },
                {
                    label: i18n.t("Email Address"),
                    field: "email",
                    sort: "asc"
                },
                {
                    label: i18n.t("Member ID"),
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: i18n.t("e-Token Purchased (Tokens)"),
                    field: "token",
                    sort: "asc"
                },
                {
                    label: `${i18n.t("Bought e-Token using")} ${this.state.country ? `(${currency(this.state.country)})` : ""}`,
                    field: "amount",
                    sort: "asc"
                },
                {
                    label: i18n.t("Transaction ID"),
                    field: "transactionId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Reference ID"),
                    field: "referenceId",
                    sort: "asc"
                },
                {
                    label: i18n.t("Method"),
                    field: "method",
                    sort: "asc"
                },
                {
                    label: i18n.t("Purchase Date"),
                    field: "date",
                    sort: "asc"
                },
                {
                    label: i18n.t("Status"),
                    field: "status",
                    sort: "asc"
                }
            ],
            rows: this.state.data
        };

        let csv = [];

        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                let data = {
                    id: this.state.data[i].id,
                    name: this.state.data[i].name,
                    number: `=""${this.state.data[i].number}""`,
                    email: this.state.data[i].email,
                    memberId: this.state.data[i].memberId,
                    token: this.state.data[i].token,
                    amount: this.state.data[i].amount,
                    transactionId: this.state.data[i].transactionId,
                    referenceId: this.state.data[i].referenceId,
                    method: this.state.data[i].method,
                    date: this.state.data[i].date,
                    status: this.state.data[i].status,
                }
                csv.push(data);
            }
        }

        const csvData = {
            headers: [
                { label: "ID", key: "id" },
                { label: "User Name", key: "name" },
                { label: "Phone Number", key: "number" },
                { label: "Email Address", key: "email" },
                { label: "Member ID", key: "memberId" },
                { label: "e-Token Purchased (Tokens)", key: "token" },
                { label: `Bought e-Token using ${this.state.country ? `(${currency(this.state.country)})` : ""}`, key: "amount" },
                { label: "Transaction ID", key: "transactionId" },
                { label: "Reference ID", key: "referenceId" },
                { label: "Method", key: "method" },
                { label: "Purchase Date", key: "date" },
                { label: "Status", key: "status" }
            ],
            data: csv
        };

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">{i18n.t("e-Token Purchase History")}</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>{i18n.t("Filter")}</h6>
                                    <div>
                                        <label htmlFor="distributorId">{i18n.t("Select Distributor")}: </label>
                                        <Select
                                            id="distributorId"
                                            style={{ width: '100%' }}
                                            placeholder={i18n.t("Select Distributor")}
                                            value={this.state.distributorId}
                                            onChange={this.handleChangeDistributor}
                                            optionFilterProp="children"
                                        >
                                            <Select.Option disabled value="">{i18n.t("Select Distributor")}</Select.Option>
                                            {
                                                this.props.client.distributor.map(distributor => (
                                                    <Select.Option key={distributor.id} value={distributor.id}>
                                                        {distributor.name}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </div>

                                    {/* <div>
                                        <label htmlFor="operatorId">Select Operator: </label>
                                        <select id="operatorId" name="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleChange}>
                                            <option disabled value="">Select Operator</option>
                                            {
                                                this.state.distributorId ? this.state.operators.filter(x => x.token === true).map(admin => <option key={admin.id} value={admin.id}>{admin.username}</option>) : null
                                            }
                                        </select>
                                    </div> */}
                                    <Row gutter={16} className="mt-3">
                                        <Col span={24}>
                                            <label htmlFor="date">{i18n.t("Select Purchase Date")}: </label>
                                            <RangePicker
                                                onChange={this.handleDate}
                                                style={{ width: '100%', marginBottom: '10px' }}
                                                value={this.state.startDate && this.state.endDate ? [dayjs(this.state.startDate), dayjs(this.state.endDate)] : null}
                                                ranges={{
                                                    'Today': [dayjs(), dayjs()],
                                                    'Yesterday': [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')],
                                                    'Last 7 Days': [dayjs().subtract(6, 'day'), dayjs()],
                                                    'Last 30 Days': [dayjs().subtract(29, 'day'), dayjs()],
                                                    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
                                                    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                    <div className="d-flex">
                                        <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
                                            {i18n.t("Filter")}
                                        </button>
                                        <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
                                            {i18n.t("Reset")}
                                        </button>
                                    </div>
                                </div>
                                {
                                    this.state.loading ? (
                                        <Loading />
                                    ) : (
                                        <div>
                                            <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`etoken_purchase_history_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                                    {i18n.t("Download Report")}
                                                </CSVLink>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(TokenPurchaseHistory);