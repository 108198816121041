import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { currency } from "../../../dynamicController";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";

import Sidebar from "../utilities/Sidebar";
import WashupHeader from "./dashboard/header/washupHeader";

import ticketBorder from "../../.././assets/images/backgrounds/Vector(11).png";
import { changeNav, updateUser } from "../../../redux/actions/userActions";

const mySwal = withReactContent(Swal);

const colorSets = [
  {
    headColor: "#c596c4",
    bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)",
  },
  {
    headColor: "#fbcc7d",
    bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)",
  },
  {
    headColor: "#93c9ed",
    bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)",
  },
  {
    headColor: "#c7a1aa",
    bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)",
  },
  {
    headColor: "#b6da98",
    bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)",
  },
];

let index = 0;

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Coupon = ({ user, changeNav, token, updateUser }) => {

  const [toggled, setToggled] = useState(false);
  const [past, setPast] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    index = 0;

    return function cleanup() {
      index = 0;
    };
  }, []);

  const washupTitle = (from) => {
    if (from === "Register") {
      return "Welcome"
    } else if (from === "Birthday") {
      return "Birthday"
    } else if (from === "Referral") {
      return "Referral"
    } else if (from === "Reload") {
      return "Reload"
    } else {
      return "Congratulation"
    }
  }

  const washupDescription = (from, description) => {
    if (from === "Register") {
      return (
        <>
          <p className="m-0 p-0">Voucher</p>
          <p className="m-0 p-0" style={{ color: "#CFA82C", fontSize: "small" }}>NEW USER</p>
        </>
      )
    } else if (from === "Birthday") {
      return (
        <>
          <p className="m-0 p-0">Voucher</p>
          <p className="m-0 p-0" style={{ color: "#CFA82C", fontSize: "x-small" }}>BIRTHDAY GIFT</p>
          <p className="m-0 p-0" style={{ fontSize: "smaller" }}>for you</p>
        </>
      )
    } else if (from === "Referral") {
      return (
        <>
          <p className="m-0 p-0">Discount</p>
          <p className="m-0 p-0" style={{ fontSize: "smaller" }}>Share your</p>
          <p className="m-0 p-0" style={{ color: "#CFA82C", fontSize: "xx-small" }}>REFERRAL CODE</p>
        </>
      )
    } else if (from === "Stamp") {
      return (
        <>
          <p className="m-0 p-0" style={{ color: "#CFA82C", fontSize: "xx-small" }}>You have completed</p>
          <p className="m-0 p-0" style={{ fontSize: "smaller" }}>{description && description.includes("washer") ? "washer " : description && description.includes("dryer") ? "dryer " : ""}stamp</p>
          <p className="m-0 p-0" style={{ color: "#CFA82C" }}>Collection</p>
        </>
      )
    } else {
      return (
        <p className="m-0 p-0" style={{ width: "30%", fontSize: "smaller" }}>{description}</p>
      )
    }
  }

  const renderVoucherScheduler = () => {
    if (user) {
      return user.voucherScheduler
        .map((voucher) => {
          const randomNumber =
            index; /*Math.floor(Math.random() * ((colorSets.length - 1) + 1));*/

          if (index === colorSets.length - 1) {
            index = 0;
          } else {
            index += 1;
          }

          let currentVoucherAmount = voucher.amount

          return (
            <>
              {STORETYPE === "cleanpro" ? (
                <div
                  style={{ borderRadius: "0" }}
                  className="voucher mb-3"
                  key={voucher.id}
                  onClick={() => voucher.from === "Register" ? history.push("/user/news/cleanproWelcome") : null}
                >
                  <img className="voucher-border" src={ticketBorder} alt="" />
                  <div
                    className="voucher-content d-flex"
                    style={{ color: "#999999", position: "relative", zIndex: "99" }}
                  >
                    <div
                      className="d-flex"
                      style={{ width: "70%", flexDirection: "column", justifyContent: "center", marginLeft: "1em" }}
                    >
                      <h2 className="font-weight-bold">
                        {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                      </h2>

                      <small>{voucher.description}</small>

                      <h6 className="m-0 p-0">
                        <small>{t("Available On")}: {new Date(voucher.grant_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                      </h6>

                      {voucher.expired_date ? (
                        <h6 className="m-0 p-0">
                          <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                        </h6>
                      ) : null}

                    </div>
                    <div style={{ width: "30%" }} className="center-content">
                      <p className="text-center font-weight-bold">
                        {t("Not available")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : STORETYPE === "washup" ? (
                <div className={`voucher d-flex flex-column justify-content-between px-2 mb-3 ${voucher.from}`} key={voucher.id}>
                  <div style={{ paddingTop: "5%", paddingLeft: "12%", lineHeight: "normal" }}>
                    {voucher.type === "Flat" ? (
                      <p className="m-0 p-0">{currency(user.country)}<span style={{ fontSize: "large" }}>{voucher.amount}</span></p>
                    ) : (
                      <p className="m-0 p-0"><span style={{ fontSize: "large" }}>{voucher.amount}</span>%</p>
                    )}
                    {washupDescription(voucher.from, voucher.description)}
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 p-0 align-content-end">{t(washupTitle(voucher.from))}</p>
                    <div>
                      <p className="m-0 p-0" style={{ color: "#CFA82C", fontSize: "smaller" }}>
                        {t("Available On")}: {new Date(voucher.grant_date).getDate()}/{new Date(voucher.grant_date).getMonth() + 1}/{new Date(voucher.grant_date).getFullYear()}
                      </p>
                      {
                        voucher.expired_date ? (
                          <p className="m-0 p-0" style={{ color: "#CFA82C", fontSize: "smaller" }}>
                            {t("Expired Date")}: {new Date(voucher.expired_date).getDate()}/{new Date(voucher.expired_date).getMonth() + 1}/{new Date(voucher.expired_date).getFullYear()}
                          </p>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <div className="voucher mb-3" key={voucher.id}>
                  <div className="voucher-ball left-ball"></div>
                  <div
                    className="voucher-head"
                    style={{
                      backgroundColor: colorSets[randomNumber].headColor,
                    }}
                  >
                    <h4>{t("DISCOUNT")}</h4>
                  </div>

                  <div
                    className="voucher-content"
                    style={{ background: colorSets[randomNumber].bodyColor }}
                  >
                    <div>
                      <h1>
                        {voucher.type === "Flat" ? `${currency(user.country)}${voucher.amount}` : `${voucher.amount}%`}
                      </h1>
                    </div>

                    <div>
                      <h5>{voucher.title}</h5>
                      <h6 className="m-0 p-0">{voucher.description}</h6>
                      <h6
                        style={{ color: "red", textAlign: "right" }}
                        className="m-0 p-0"
                      >
                        <small>
                          {t("Available On")}: {new Date(voucher.grant_date).getDate()}/{new Date(voucher.grant_date).getMonth() + 1}/{new Date(voucher.grant_date).getFullYear()}
                        </small>
                      </h6>
                      {voucher.expired_date ? (
                        <h6
                          style={{ color: "red", textAlign: "right" }}
                          className="m-0 p-0"
                        >
                          <small>
                            {t("Expired Date")}: {new Date(voucher.expired_date).getDate()}/{new Date(voucher.expired_date).getMonth() + 1}/{new Date(voucher.expired_date).getFullYear()}
                          </small>
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="voucher-ball right-ball"></div>
                </div>
              )}
            </>
          );
        });
    }
  };

  const renderCoupons = () => {
    if (user) {
      return user.vouchers
        .filter((voucher) => voucher.available)
        .map((voucher) => {
          const randomNumber =
            index; /*Math.floor(Math.random() * ((colorSets.length - 1) + 1));*/

          if (index === colorSets.length - 1) {
            index = 0;
          } else {
            index += 1;
          }

          let currentVoucherAmount = voucher.amount

          return (
            <>
              {STORETYPE === "cleanpro" ? (
                <div
                  style={{ borderRadius: "0" }}
                  className="voucher mb-3"
                  key={voucher.id}
                  onClick={() => voucher.from === "Register" ? history.push("/user/news/cleanproWelcome") : null}
                >
                  <img className="voucher-border" src={ticketBorder} alt="" />
                  <div
                    className="voucher-content d-flex"
                    style={{ color: "black", position: "relative", zIndex: "99" }}
                  >
                    <div
                      className="d-flex"
                      style={{ width: "70%", flexDirection: "column", justifyContent: "center", marginLeft: "1em" }}
                    >
                      <h2 className="font-weight-bold">
                        {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                      </h2>

                      <small>{voucher.description}</small>

                      {voucher.expired_date ? (
                        <h6 style={{ color: "grey" }} className="m-0 p-0">
                          <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                        </h6>
                      ) : null}

                    </div>
                    <div style={{ width: "30%" }} className="center-content">
                      <p className="text-center font-weight-bold blue-t">
                        {t("Available")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : STORETYPE === "washup" ? (
                <div className={`voucher d-flex flex-column justify-content-between px-2 mb-3 ${voucher.from}`} key={voucher.id}>
                  <div style={{ paddingTop: "5%", paddingLeft: "12%", lineHeight: "normal" }}>
                    {voucher.type === "Flat" ? (
                      <p className="m-0 p-0">{currency(user.country)}<span style={{ fontSize: "large" }}>{voucher.amount}</span></p>
                    ) : (
                      <p className="m-0 p-0"><span style={{ fontSize: "large" }}>{voucher.amount}</span>%</p>
                    )}
                    {washupDescription(voucher.from, voucher.description)}
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0 p-0 align-content-end">{t(washupTitle(voucher.from))}</p>
                    {
                      voucher.expired_date ? (
                        <small className="m-0 p-0" style={{ color: "#CFA82C" }}>{t("Expired Date")}: {new Date(voucher.expired_date).getDate()}/{new Date(voucher.expired_date).getMonth() + 1}/{new Date(voucher.expired_date).getFullYear()}</small>
                      ) : null
                    }
                  </div>
                </div>
              ) : (
                <div className="voucher mb-3" key={voucher.id}>
                  <div className="voucher-ball left-ball"></div>
                  <div
                    className="voucher-head"
                    style={{
                      backgroundColor: colorSets[randomNumber].headColor,
                    }}
                  >
                    <h4>{t("DISCOUNT")}</h4>
                  </div>

                  <div
                    className="voucher-content"
                    style={{ background: colorSets[randomNumber].bodyColor }}
                  >
                    <div>
                      <h1>
                        {voucher.type === "Flat" ? `${currency(user.country)}${voucher.amount}` : `${voucher.amount}%`}
                      </h1>
                    </div>

                    <div>
                      <h5>{voucher.title}</h5>
                      <h6 className="m-0 p-0">{voucher.description}</h6>
                      {voucher.expired_date ? (
                        <h6
                          style={{ color: "red", textAlign: "right" }}
                          className="m-0 p-0"
                        >
                          <small>
                            {t("Expired Date")}: {new Date(voucher.expired_date).getDate()}/{new Date(voucher.expired_date).getMonth() + 1}/{new Date(voucher.expired_date).getFullYear()}
                          </small>
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="voucher-ball right-ball"></div>
                </div>
              )}
            </>
          );
        });
    }
  };

  const renderUnavailableCoupons = () => {
    if (user) {
      // console.log(user.vouchers);
      return user.vouchers
        .filter((voucher) => !voucher.available)
        .map((voucher) => {
          let currentVoucherAmount = voucher.amount
          return (
            <>
              {STORETYPE === "cleanpro" && (
                <>
                  <div
                    style={{ borderRadius: "0" }}
                    className="voucher mb-3"
                    key={voucher.id}
                  >
                    <img className="voucher-border" src={ticketBorder} alt="" />
                    <div
                      className="voucher-content d-flex"
                      style={{ color: "black", position: "relative", zIndex: "99" }}
                    >
                      <div
                        className="d-flex"
                        style={{ width: "70%", flexDirection: "column", justifyContent: "center", marginLeft: "1em" }}
                      >
                        <h2 className="font-weight-bold">
                          {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                        </h2>

                        <small>{voucher.description}</small>

                        {voucher.status === "Used" ? (
                          <h6 className="m-0 p-0 t-grey">
                            <small>{t("Used On")}: {new Date(voucher.used_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                          </h6>
                        ) : (
                          <h6 className="m-0 p-0 t-grey">
                            <small>{t("Expired On")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                          </h6>
                        )}
                      </div>
                      <div style={{ width: "30%" }} className="center-content">
                        <p className="text-center font-weight-bold t-grey">
                          {t(voucher.status)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          );
        });
    }
  };

  const redeemVoucherCode = () => {
    if (!voucherCode) {
      if (STORETYPE === "cleanpro") {
        mySwal.fire({
          title: t("Error"),
          text: t("Please fill in the voucher code"),
          icon: "error",
          confirmButtonText: t("Okay"),
          confirmButtonColor: "#004FC1",
          customClass: {
            confirmButton: "cleanpro-swal-btn"
          }
        })
      } else {
        mySwal.fire(t("Error"), t("Please fill in the voucher code"), "error")
      }
    } else {
      axios.post("/api/user/redeemVoucherCode", { code: voucherCode, userId: user.id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
        .then(res => {
          setVoucherCode("");
          updateUser({ type: "voucher", voucher: res.data.voucher, voucherScheduler: res.data.voucherScheduler })
          if (STORETYPE === "cleanpro") {
            mySwal.fire({
              title: t("Success"),
              text: t("Voucher is redeemed successfully!"),
              icon: "success",
              confirmButtonText: t("Okay"),
              confirmButtonColor: "#004FC1",
              customClass: {
                confirmButton: "cleanpro-swal-btn"
              }
            })
          } else {
            mySwal.fire(t("Success"), t("Voucher is redeemed successfully!"), "success")
          }
        })
        .catch(err => {
          if (STORETYPE === "cleanpro") {
            mySwal.fire({
              title: t("Error"),
              text: t(err.response.data.error),
              icon: "error",
              confirmButtonText: t("Okay"),
              confirmButtonColor: "#004FC1",
              customClass: {
                confirmButton: "cleanpro-swal-btn"
              }
            })
          } else {
            mySwal.fire(t("Error"), t(err.response.data.error), "error")
          }
        })
    }
  }

  return (
    <div id="coupon-page">
      <Sidebar toggled={toggled} setToggled={setToggled} />
      {
        STORETYPE === "washup" ? (
          <WashupHeader user={user} setToggled={setToggled} />
        ) : (
          <div id="user-header">
            <h5>
              {
                STORETYPE === "cleanpro" ? (
                  <strong onClick={(e) => changeNav("stamp")}>
                    <i className="bi bi-chevron-left me-3"></i>
                    {t("Vouchers")}
                  </strong>
                ) : (
                  <>
                    <i
                      onClick={(e) => setToggled(true)}
                      className="bi bi-list me-3"
                    ></i>{" "}
                    <strong>{t("Voucher")}</strong>
                  </>
                )
              }
            </h5>
          </div>
        )
      }

      {STORETYPE === "cleanpro" ? (
        <>
          <div className="mt-3 px-2 d-flex justify-content-between">
            <div
              id="active-rewards"
              onClick={() => setPast(false)}
              style={{ width: "45%" }}
              className={`align-center no-wrap cursor-pointer ${!past ? "active-tab" : "inactive-tab"}`}
            >
              {t("Active Rewards")}
            </div>
            <div
              id="past-rewards"
              onClick={() => setPast(true)}
              style={{ width: "50%" }}
              className={`align-center no-wrap cursor-pointer ${past ? "active-tab" : "inactive-tab"}`}
            >
              {t("Past Rewards")}
            </div>
          </div>
          {!past ? (
            <div>
              <div style={{ backgroundColor: "#F2F2F2" }}>
                <div className="py-4 px-3">
                  <p style={{ color: "#303030", fontSize: "16px", fontWeight: "600" }}>{t("Input Promo Code")}</p>
                  <div className="d-flex">
                    <input
                      type="text"
                      name="vouchercode"
                      id="vouchercode"
                      className="form-control m-auto"
                      style={{ backgroundColor: "white" }}
                      placeholder={t("Enter a code")}
                      onChange={e => setVoucherCode(e.target.value.toUpperCase())}
                      value={voucherCode}
                    />
                    <div className="ms-2">
                      <button type="button" className="btn btn-primary big-button px-5" onClick={() => redeemVoucherCode()} disabled={!voucherCode}>{t("Apply")}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-3 py-3">
                <h3 className="font-weight-bold">{t("Get rewarded for your loyalty with Cleanpro Plus Rewards!")}</h3>
                <p className="align-justify">{t("Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!")}</p>
                <div id="coupon-body">{renderCoupons()}{renderVoucherScheduler()}</div>
              </div>
            </div>
          ) : (
            <div className="px-3 py-2">
              <div id="coupon-body">{renderUnavailableCoupons()}</div>
            </div>
          )}
        </>
      ) : (
        <div id="coupon-body">{renderCoupons()}{renderVoucherScheduler()}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    token: state.user.token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
    updateUser: (data) => dispatch(updateUser(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
