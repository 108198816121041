import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { changeNav } from "../../../../redux/actions/userActions";
import { scannedCode, saveCreds } from "../../../../redux/actions/paymentActions";
import { decrypt } from "../../../../dynamicController";
import { ProgressBar } from 'react-bootstrap';
import io from 'socket.io-client';
import axios from "axios";

// import washer from "../../../../assets/images/machines/paymentWasher.png";
// import dryer from "../../../../assets/images/machines/paymentDryer.png";
import washerIcon from "../../../../assets/images/machines/icn-washer.png";
import dryerIcon from "../../../../assets/images/machines/icn-dryer.png";
import machineIcon from "../../../../assets/images/icons/machine.png";
import timerClothIcon from "../../../../assets/images/icons/timer-cloth-icon.png";
import timerMachineIcon from "../../../../assets/images/icons/timer-machine-icon.png";
import timerHomeIcon from "../../../../assets/images/icons/timer-home-icon.png";
import laundroLogo from "../../../../assets/images/logo/laundroBlueLogo.png";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Timer = ({ tran, history, changeNav, outlet, operatorId, scanned, user, saveCredentials }) => {

    const { t } = useTranslation();

    const [showTimer, setShowTimer] = useState(false);
    const [lastTran, setLastTran] = useState(tran);
    const [running, setRunning] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [second, setSecond] = useState(0);

    let timer;

    useEffect(() => {
        axios
            .post("/api/user/cycle/check", { transactionId: tran.id })
            .then(res => {
                setLastTran(res.data.data)
                setShowTimer(true)
                if (res.data.second) {
                    const remainingTime = new Date(res.data.data.machine_end_time).getTime() - new Date().getTime()
                    const remainingSecond = Math.floor(remainingTime / 1000)
                    if (parseInt(remainingSecond / 60) >= 0 && parseFloat(remainingSecond / 60) <= res.data.data.machine_duration) {
                        setSecond(remainingSecond)
                    } else {
                        setSecond(res.data.second)
                    }
                    setRunning(true)
                } else {
                    setSecond(parseFloat(res.data.data.machine_duration) * 60)
                    const socket = io({ auth: { transactionId: tran.id } })
                    socket.on("startMachine", (data) => {
                        setLastTran(data.transaction)
                        const remainingTime = new Date(data.transaction.machine_end_time).getTime() - new Date().getTime()
                        const remainingSecond = Math.floor(remainingTime / 1000)
                        if (parseInt(remainingSecond / 60) >= 0 && parseFloat(remainingSecond / 60) <= data.transaction.machine_duration) {
                            setSecond(remainingSecond)
                        } else {
                            setSecond(data.second)
                        }
                        setRunning(true)
                    })
                }
            })
            .catch(err => {
                setShowTimer(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tran])

    useEffect(() => {
        if (running) {
            startTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [second, running])

    const startTimer = () => {
        timer = setTimeout(() => {
            if (second > 0) {
                const remainingTime = new Date(lastTran.machine_end_time).getTime() - new Date().getTime()
                const remainingSecond = Math.floor(remainingTime / 1000)
                if (parseInt(remainingSecond / 60) >= 0 && parseFloat(remainingSecond / 60) <= lastTran.machine_duration) {
                    setSecond(remainingSecond)
                } else {
                    setSecond(second - 1)
                }
            } else {
                stopTimer()
                setCompleted(true)
                setRunning(false)
            }
        }, 1000)
    }

    const stopTimer = () => {
        clearTimeout(timer);
    }

    const addDryingTime = (machineNo) => {

        const sendThis = {
            machineNo,
            operatorId: operatorId || user.store
        }

        axios
            .post("/api/user/getMachine", sendThis)
            .then((res) => {
                const response = decrypt(res.data.data);
                scanned(response);
                saveCredentials({ machineNo })
                history.push("/user/payment", { addDryingTime: parseInt(second / 60) > 2 ? true : false });
            })
            .catch((err) => {
                changeNav("camera")
            });
    }

    if (STORETYPE === "cleanpro") {
        return (
            <div key={tran.id}>
                {
                    showTimer ? (
                        <div className="mb-3">
                            <div id="machine-instruction-container">
                                <div id="machine-info-container">
                                    <div id="machine-image-container">
                                        <h3>{lastTran.machine_type === "Washer" ? t("Wash") : lastTran.machine_type === "Dryer" ? t("Dry") : ""} </h3>

                                        {
                                            lastTran.machine_type === "Washer" ? (
                                                <img src={washerIcon} alt="washer" className="img-fluid" />
                                            ) : lastTran.machine_type === "Dryer" ? (
                                                <img src={dryerIcon} alt="dryer" className="img-fluid" />
                                            ) : null
                                        }
                                    </div>

                                    <div id="machine-info">
                                        {completed ? (
                                            <p style={{ backgroundColor: "#409035" }} className="status-label py-1 my-1">{t("COMPLETED")}</p>
                                        ) : running ? (
                                            <p style={{ backgroundColor: "#004FC1" }} className="status-label py-1 my-1">{lastTran.machine_type === "Washer" ? t("WASHING NOW") : lastTran.machine_type === "Dryer" ? t("DRYING NOW") : ""}</p>
                                        ) : null}
                                        <h5>
                                            {lastTran.machine_name}
                                        </h5>

                                        <div className="mt-2" id="badge-container">
                                            <div>
                                                <p style={{ fontSize: "12px", color: "#525252" }}>{t("Capacity")}</p>
                                                <strong style={{ fontSize: "16px" }}>{lastTran.machine_capacity}</strong>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: "12px", color: "#525252" }}>{t("Duration")}</p>
                                                <strong style={{ fontSize: "16px" }}>
                                                    {lastTran.machine_total_duration} {t("min")}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {
                                    completed ? (
                                        <div id="complete-timer-container" className="py-3 px-4">
                                            <p style={{ fontSize: "22px", fontWeight: 500 }}>{t("It's done!")}</p>
                                            <p style={{ fontSize: "16px", fontWeight: 300 }} className="pt-1">{t("Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!")}</p>
                                            <p className="text-end mt-2" style={{ fontSize: "16px", fontWeight: 300 }} id="timer-feedback" onClick={() => history.push("/user/feedback")}>{t("Leave a feedback")} &gt;</p>
                                        </div>
                                    ) : lastTran.machine_type === "Washer" ? (
                                        <div id="machine-instruction" className="py-3 px-3">
                                            <div className="text-center">
                                                <p style={{ color: "#525252", fontSize: "12px" }}>{t("Time left")}</p>
                                                <p style={{ fontSize: "30px", fontWeight: 500 }}>{parseInt(second / 60).toString().length === 1 ? `0${parseInt(second / 60)}` : parseInt(second / 60)} : {parseInt(second % 60).toString().length === 1 ? `0${parseInt(second % 60)}` : parseInt(second % 60)}</p>
                                                <small>{t("It's indicative and may change based on washer load")}</small><br />
                                                <small>{t("Need to refresh to update")}</small>
                                            </div>
                                        </div>
                                    ) : lastTran.machine_type === "Dryer" ? (
                                        <div id="machine-instruction" className="py-3 px-3">
                                            <div className="d-flex justify-content-around align-items-center">
                                                <div>
                                                    <p style={{ color: "#525252", fontSize: "12px" }}>{t("Time left")}</p>
                                                    <p style={{ fontSize: "30px", fontWeight: 500 }}>{parseInt(second / 60).toString().length === 1 ? `0${parseInt(second / 60)}` : parseInt(second / 60)} : {parseInt(second % 60).toString().length === 1 ? `0${parseInt(second % 60)}` : parseInt(second % 60)}</p>
                                                </div>
                                                <div>
                                                    <button id="add-drying-time" className="btn secondary-button px-4" onClick={e => addDryingTime(lastTran.machine_no)}>{t("Add drying time")}</button>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <small>{t("Need to refresh to update")}</small>
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    } else {
        return (
            <div key={tran.id}>
                {/* {
                    showTimer ? (
                        <div className="pt-4">
                            <div id="thankyou-machine-info-container">
                                <div id="thankyou-machine-image-container">
                                    {
                                        lastTran.machine_type === "Washer" ? (
                                            <img src={washer} alt="washer" className="img-fluid" />
                                        ) : lastTran.machine_type === "Dryer" ? (
                                            <img src={dryer} alt="dryer" className="img-fluid" />
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                <div id="thankyou-machine-info">
                                    <div>
                                        <h5 className="m-0 p-0" style={{ color: "black" }}>{t(lastTran.machine_type)} - {lastTran.machine_name}</h5>
                                        <h6 className="m-0 p-0" style={{ color: "black" }}>{lastTran.machine_capacity}</h6>
                                        {
                                            completed ? (
                                                <h5 className="m-0 p-0 timer-title" style={{ color: "black" }}>{lastTran.machine_type === "Washer" ? t("Washing Complete") : lastTran.machine_type === "Dryer" ? t("Drying Complete") : ""}</h5>
                                            ) : (
                                                <h5 className="m-0 p-0 timer-title" style={{ color: "black" }}>{t("Time left")}: <b className="h3">{parseInt(second / 60).toString().length === 1 ? `0${parseInt(second / 60)}` : parseInt(second / 60)} : {parseInt(second % 60).toString().length === 1 ? `0${parseInt(second % 60)}` : parseInt(second % 60)}</b></h5>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                } */}

                {
                    showTimer ? (
                        <div className="shadow px-5 py-4 mt-4 mb-3" style={{ backgroundColor: "white" }}>
                            <h4><b>{t("Finish in")} {parseInt(second / 60).toString().length === 1 ? `0${parseInt(second / 60)}` : parseInt(second / 60)} : {parseInt(second % 60).toString().length === 1 ? `0${parseInt(second % 60)}` : parseInt(second % 60)} {t("min")}</b></h4>
                            {lastTran.machine_type === "Washer" ? <><small>{t("It's indicative and may change based on washer load")}</small><br /></> : null}
                            <small>{t("Need to refresh to update")}</small>
                            {lastTran.notification_type ? <p>{t("We'll let you know when it's ready to pick up")}</p> : null}
                            <div className="d-flex">
                                <div className="pe-1">
                                    <img src={laundroLogo} alt="" style={{ width: "25px" }} />
                                </div>
                                <ProgressBar now={100} style={{ height: "8px" }} />
                                <div className="px-1">
                                    <img src={timerMachineIcon} alt="" style={{ width: "25px" }} />
                                </div>
                                <ProgressBar now={completed ? 100 : !running ? 0 : 100 - (second / (lastTran.machine_total_duration * 60)) * 100} style={{ backgroundColor: running ? "#B6D0E2" : "grey", height: "8px" }} />
                                <div className="px-1">
                                    <img src={timerClothIcon} alt="" style={{ width: "25px" }} />
                                </div>
                                <ProgressBar now={completed ? 100 : 0} style={{ backgroundColor: "grey", height: "8px" }} />
                                <div className="ps-1">
                                    <img src={timerHomeIcon} alt="" style={{ width: "25px" }} />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div style={{ width: "80%" }}>
                                    <h4 className="pt-2">{lastTran.machine_name}</h4>
                                    <div className="d-flex">
                                        <p style={{ width: "50%" }}>{t("Capacity")}</p>
                                        <p style={{ width: "50%" }}>{t("Duration")}</p>
                                    </div>
                                    <div className="d-flex">
                                        <p style={{ width: "50%", lineHeight: "10%" }}><b>{lastTran.machine_capacity}</b></p>
                                        <p style={{ width: "50%", lineHeight: "10%" }}><b>{lastTran.machine_total_duration} {t("min")}</b></p>
                                    </div>
                                </div>
                                <div style={{ display: "block", margin: "auto" }}>
                                    <img src={machineIcon} alt="" style={{ width: "50px" }} />
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        operatorId: state.user.operatorId,
        user: state.user.user,
        outlet: state.payment.outlet
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeNav: (nav) => dispatch(changeNav(nav)),
        scanned: (data) => dispatch(scannedCode(data)),
        saveCredentials: (data) => dispatch(saveCreds(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);