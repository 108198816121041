import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";

import ticket from "../../../../assets/images/icons/icn-voucher.png";
import creditBear from "../../../../assets/images/icons/icn-creditGrey.png";
import cleanproCreditIcon from "../../../../assets/images/icons/icn-credit.png";
import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;
const apiUrl = process.env.REACT_APP_API_URL;

const Payment = ({
    setPage,
    cart,
    setCart,
    machine,
    notificationType,
    setNotificationType,
    email,
    setEmail,
    number,
    setNumber,
    user,
    voucherId,
    setVoucherId,
    discountCode,
    setDiscountCode,
    discountAmount,
    setDiscountAmount,
    theme,
    point,
    setPoint
}) => {
    const [list, setList] = useState([]);
    const [discountCodeModal, setDiscountCodeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectVoucherId, setSelectVoucherId] = useState(null);
    const [discountCodeInput, setDiscountCodeInput] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [insufficient, setInsufficient] = useState(false);
    const [razerBody, setRazerBody] = useState(null);
    const [ghlBody, setGhlBody] = useState(null);
    const [duitnowUrl, setDuitnowUrl] = useState("");

    const razerButtonClick = useRef();
    const duitnowDownloadClick = useRef();
    const ghlButtonClick = useRef();

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        let itemList = []
        for (let i = 0; i < cart.length; i++) {
            let pack = itemList.filter(item => item.sequence === cart[i].sequence)[0]
            if (pack) {
                if (cart[i].name === "Wash") {
                    pack.wash = true
                    pack.washCapacity = cart[i].capacity
                    pack.washMode = cart[i].mode
                    pack.washPrice = cart[i].price
                } else if (cart[i].name === "Dry") {
                    pack.dry = true
                    pack.dryCapacity = cart[i].capacity
                    pack.dryDuration = cart[i].machine_duration
                    pack.dryPrice = cart[i].price
                } else if (cart[i].name === "Fold") {
                    pack.fold = true
                    pack.foldPrice = cart[i].price
                }
            } else {
                let tempObject = {
                    sequence: cart[i].sequence,
                    size: cart[i].size
                }
                if (cart[i].name === "Wash") {
                    tempObject.wash = true
                    tempObject.washCapacity = cart[i].capacity
                    tempObject.washMode = cart[i].mode
                    tempObject.washPrice = cart[i].price
                } else if (cart[i].name === "Dry") {
                    tempObject.dry = true
                    tempObject.dryCapacity = cart[i].capacity
                    tempObject.dryDuration = cart[i].machine_duration
                    tempObject.dryPrice = cart[i].price
                } else if (cart[i].name === "Fold") {
                    tempObject.fold = true
                    tempObject.foldPrice = cart[i].price
                }
                itemList.push(tempObject)
            }
        }
        setList(itemList)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart])

    useEffect(() => {
        if (razerBody) {
            razerButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [razerBody]);

    useEffect(() => {
        if (duitnowUrl) {
            duitnowDownloadClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duitnowUrl]);

    useEffect(() => {
        if (ghlBody) {
            ghlButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ghlBody]);

    const totalAmount = (type) => {
        let total = cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)
        let payAmount = total
        let pointAmount = 0

        // promotion / voucher / discount code
        if (discountAmount) {
            if (discountAmount.type === "Flat") {
                payAmount -= discountAmount.amount
            } else {
                const rate = (payAmount * discountAmount.amount) / 100
                payAmount = parseFloat(payAmount - rate).toFixed(2)
            }
        }

        if (payAmount <= 0) {
            payAmount = 0
        }

        // point
        if (point) {
            if (parseFloat(user.point) >= (parseFloat(payAmount) * parseFloat(user.point_currency))) {
                // if user have sufficient point to pay (all amount pay using point)
                pointAmount = parseFloat(payAmount) * parseFloat(user.point_currency)
                payAmount = 0
            } else {
                // use up all the current point and user still need to pay for the remaining amount
                pointAmount = parseFloat(user.point)
                payAmount = parseFloat(payAmount) - (parseFloat(user.point) / parseFloat(user.point_currency))
            }
        }

        if (type === "amount") {
            return /\./g.test(total.toString()) ? parseFloat(total).toFixed(2) : total
        } else if (type === "paymentAmount") {

            if (notificationType === "SMS" && !(user && machine.member_free_sms)) {
                payAmount = parseFloat(payAmount) + parseFloat(machine.sms_notification_amount)
            }
            if (payAmount <= 0) {
                return 0
            } else {
                return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount
            }
        } else if (type === "paymentAmountWithoutSMS") {

            if (payAmount <= 0) {
                return 0
            } else {
                return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount
            }
        } else if (type === "pointAmount") {
            // point amount use for discount
            return pointAmount
        } else if (type === "pointValue") {
            // point amount use for discount in RM
            return /\./g.test((parseFloat(pointAmount) / parseFloat(user.point_currency)).toString()) ? (parseFloat(pointAmount) / parseFloat(user.point_currency)).toFixed(2) : (parseFloat(pointAmount) / parseFloat(user.point_currency))
        } else if (type === "sms") {
            if (user && machine.member_free_sms) {
                return 0;
            } else {
                return /\./g.test(machine.sms_notification_amount.toString()) ?
                    parseFloat(machine.sms_notification_amount).toFixed(2) :
                    machine.sms_notification_amount;
            }
        }
    }

    const discountText = () => {
        if (discountAmount) {
            if (discountAmount.type === "Flat") {
                if (STORETYPE === "cleanpro" && user && user.tokenSetting) {
                    return `${discountAmount.amount} ${t("credit")}`
                } else {
                    return `${currency(machine.country)} ${discountAmount.amount}`
                }
            } else {
                return `${discountAmount.amount} %`
            }
        }
    }

    const removeDiscount = () => {
        setVoucherId(null)
        setDiscountCode("")
        setDiscountCodeInput("");
        setDiscountAmount(null)
        setOpen(false)
        setDiscountCodeModal(false)
    }

    const checkDiscountCode = (e) => {
        e.preventDefault();

        setLoading(true);
        axios
            .post("/api/user/checkDiscountCode", {
                discountCode: discountCodeInput,
                outletId: machine.outletId,
                machineType: "Package",
                userId: user.id,
            })
            .then((res) => {
                setDiscountCode(discountCodeInput);
                setDiscountAmount({
                    type: res.data.discountType,
                    amount: res.data.discountAmount
                })
                setVoucherId(null)
                setDiscountCodeModal(false);
                setLoading(false);
            })
            .catch((err) => {
                setDiscountCodeInput("");
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const deleteItem = (sequence) => {
        mySwal.fire({
            title: t("Confirmation"),
            text: t("Are you sure to delete the item?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonText: t("Delete"),
            reverseButtons: STORETYPE === "cleanpro" ? true : false,
            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
            customClass: {
                confirmButton: "cleanpro-swal-btn",
                cancelButton: "cleanpro-swal-secondary-btn"
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let deletedCart = cart.filter(c => c.sequence !== sequence)
                    setCart(deletedCart)
                }
            })
    }

    const pay = (e) => {
        if (cart.length <= 0) {
            mySwal.fire(t("No Item"), t("Please add item"), "info");
        } else {
            e.target.disabled = true;
            let voucherReminder = false;

            if (user) {
                if (user.vouchers && user.vouchers.length) {
                    let userVoucher = user.vouchers.filter((voucher) => voucher.available && voucher.package_use);
                    if (userVoucher.length !== 0 && !discountAmount) {
                        voucherReminder = true;
                    }
                }
            }

            const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            const numberRegex = /^\d+$/;

            if (notificationType === "Email" && !email) {
                mySwal.fire("", t("Please fill in your email address"), "info")
                e.target.disabled = false;
            } else if (notificationType === "Email" && !emailRegex.test(email)) {
                mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
                e.target.disabled = false;
            } else if (notificationType === "SMS" && !number) {
                mySwal.fire("", t("Please fill in your phone number"), "info")
                e.target.disabled = false;
            } else if (notificationType === "SMS" && machine.country === "Taiwan" && (!numberRegex.test(number) || number.length !== 10 || number.substring(0, 2) !== "09")) {
                mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
                e.target.disabled = false;
            } else if (notificationType === "SMS" && machine.country === "Malaysia" && (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substring(0, 2) !== "01")) {
                mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
                e.target.disabled = false;
            } else {
                if (voucherReminder) {
                    mySwal
                        .fire({
                            title: t("You haven't applied your voucher"),
                            text: t("Would you like to continue your payment without using your voucher?"),
                            showCancelButton: true,
                            confirmButtonText: t("Continue"),
                            cancelButtonText: t("Apply voucher"),
                            customClass: {
                                confirmButton: "pay-without-voucher",
                                cancelButton: "apply-voucher",
                            },
                        })
                        .then((click) => {
                            if (click.isConfirmed) {
                                if (user && user.tokenSetting && user.epayment) {
                                    // E-payment or E-token
                                    mySwal
                                        .fire({
                                            title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                                            text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                                            showDenyButton: true,
                                            showCancelButton: true,
                                            cancelButtonText: machine.duitnow ? t("Duitnow QR code") : t("Cancel"),
                                            denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token"),
                                            denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#67b668",
                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                            customClass: {
                                                denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : "e-wallet",
                                                confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : "e-token",
                                                cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : "cancel-payment"
                                            },
                                        })
                                        .then((click) => {
                                            if (click.isDenied) {
                                                // E WALLET PAYMENT (DEFAULT)
                                                const text = () => {
                                                    if (discountAmount && discountAmount.type === "Flat" && discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)) {
                                                        return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                                    } else {
                                                        return `${t("Pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                                    }
                                                };

                                                let sendThis = {
                                                    amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                    paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                    outletId: machine.outletId,
                                                    transactionItem: cart,
                                                    notificationType,
                                                    email,
                                                    sms_amount: totalAmount("sms"),
                                                    number
                                                };
                                                let endPoint = "/api/nonuser/dng/payment";
                                                if (user) {
                                                    sendThis.voucherId = voucherId;
                                                    sendThis.memberId = user.memberId;
                                                    sendThis.discountCode = discountCode;
                                                    sendThis.point_amount = totalAmount("pointAmount");
                                                    sendThis.point_currency = user.point_currency;
                                                    endPoint = "/api/user/dng/payment";
                                                }

                                                mySwal
                                                    .fire({
                                                        title: t("Confirmation"),
                                                        text: text(),
                                                        icon: "question",
                                                        showCancelButton: true,
                                                        cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                        confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                        reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                        confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                        customClass: {
                                                            confirmButton: "cleanpro-swal-btn",
                                                            cancelButton: "cleanpro-swal-secondary-btn"
                                                        }
                                                    })
                                                    .then((result) => {
                                                        if (result.isConfirmed) {
                                                            mySwal
                                                                .fire({
                                                                    text: t("Processing ..."),
                                                                    allowOutsideClick: false,
                                                                    didOpen: () => {
                                                                        mySwal.showLoading();
                                                                        axios
                                                                            .post(endPoint, sendThis)
                                                                            .then((res) => {
                                                                                if (res.status === 200) {
                                                                                    if (res.data.status === "rm") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        window.location.href = res.data.data;
                                                                                    } else if (res.data.status === "laundro") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        history.push("/thankyou");
                                                                                    } else if (res.data.status === "razer") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        setRazerBody(res.data.data)
                                                                                    } else if (res.data.status === "duitnow") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        setDuitnowUrl(res.data.data)
                                                                                        history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                                    } else if (res.data.status === "ghl") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        setGhlBody(res.data.data)
                                                                                    } 
                                                                                } else {
                                                                                    e.target.disabled = false;
                                                                                    mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                }
                                                                            })
                                                                            .catch((err) => {
                                                                                mySwal
                                                                                    .fire(t("Error"), t(err.response.data.error), "error")
                                                                                    .then(() => (e.target.disabled = false));
                                                                            });
                                                                    },
                                                                })
                                                                .then(() => {
                                                                    e.target.disabled = false;
                                                                });
                                                        } else {
                                                            e.target.disabled = false;
                                                        }
                                                    });
                                            } else if (click.isConfirmed) {
                                                // TOKEN PAYMENT
                                                if (parseFloat(user.token) < parseFloat(totalAmount("paymentAmount"))) {
                                                    // no token or not enough token
                                                    if (STORETYPE === "cleanpro") {
                                                        setInsufficient(true);
                                                        e.target.disabled = false;
                                                    } else {
                                                        mySwal
                                                            .fire({
                                                                title: t("Insufficient e-Tokens"),
                                                                text: t("Reload your e-token now?"),
                                                                showCancelButton: true,
                                                                cancelButtonText: t("Cancel"),
                                                                confirmButtonText: t("Reload now!"),
                                                                customClass: {
                                                                    confirmButton: "reload-token",
                                                                },
                                                            })
                                                            .then((result) => {
                                                                if (result.isConfirmed && result.value)
                                                                    history.push("/user/reload");
                                                                e.target.disabled = false;
                                                            });
                                                    }
                                                } else {
                                                    mySwal
                                                        .fire({
                                                            title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                                            html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${totalAmount("paymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.outlet_name}</strong>?`,
                                                            icon: "question",
                                                            showCancelButton: true,
                                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                            customClass: {
                                                                confirmButton: "cleanpro-swal-btn",
                                                                cancelButton: "cleanpro-swal-secondary-btn"
                                                            }
                                                        })
                                                        .then((result) => {
                                                            if (result.isConfirmed && result.value) {
                                                                mySwal
                                                                    .fire({
                                                                        text: t("Processing ..."),
                                                                        allowOutsideClick: false,
                                                                        didOpen: () => {
                                                                            mySwal.showLoading();
                                                                            const sendThis = {
                                                                                amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                                                paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                                                outletId: machine.outletId,
                                                                                transactionItem: cart,
                                                                                memberId: user.memberId,
                                                                                token: true,
                                                                                voucherId,
                                                                                discountCode,
                                                                                notificationType,
                                                                                email,
                                                                                sms_amount: totalAmount("sms"),
                                                                                number,
                                                                                point_amount: totalAmount("pointAmount"),
                                                                                point_currency: user.point_currency,
                                                                            };
                                                                            axios
                                                                                .post("/api/user/dng/payment", sendThis)
                                                                                .then((res) => {
                                                                                    if (res.status === 200) {
                                                                                        if (res.data.status === "rm") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            window.location.href = res.data.data;
                                                                                        } else if (res.data.status === "laundro") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            history.push("/thankyou");
                                                                                        } else if (res.data.status === "razer") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setRazerBody(res.data.data)
                                                                                        } else if (res.data.status === "duitnow") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setDuitnowUrl(res.data.data)
                                                                                            history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                                        } else if (res.data.status === "ghl") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setGhlBody(res.data.data)
                                                                                        } 
                                                                                    } else {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                    }
                                                                                })
                                                                                .catch((err) => {
                                                                                    mySwal
                                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                                        .then(() => (e.target.disabled = false));
                                                                                });

                                                                            e.target.disabled = false;
                                                                        },
                                                                    })
                                                                    .then(() => {
                                                                        e.target.disabled = false;
                                                                    });
                                                            } else {
                                                                e.target.disabled = false;
                                                            }
                                                        });
                                                }
                                            } else if (click.isDismissed && click.dismiss === "cancel" && machine.duitnow) {
                                                // Pay with Duitnow QR code
                                                let sendThis = {
                                                    amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                    paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                    outletId: machine.outletId,
                                                    transactionItem: cart,
                                                    notificationType,
                                                    email,
                                                    sms_amount: totalAmount("sms"),
                                                    number,
                                                    duitnow: true
                                                };
                                                let endPoint = "/api/nonuser/dng/payment";
                                                if (user) {
                                                    sendThis.voucherId = voucherId;
                                                    sendThis.memberId = user.memberId;
                                                    sendThis.discountCode = discountCode;
                                                    sendThis.point_amount = totalAmount("pointAmount");
                                                    sendThis.point_currency = user.point_currency;
                                                    endPoint = "/api/user/dng/payment";
                                                }

                                                mySwal
                                                    .fire({
                                                        title: t("Confirmation"),
                                                        html: <Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: totalAmount("paymentAmount") }} to {{ machineName: machine.outlet_name }}?</Trans>,
                                                        icon: "question",
                                                        showCancelButton: true,
                                                        cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                        confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                        reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                        confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                        customClass: {
                                                            confirmButton: "cleanpro-swal-btn",
                                                            cancelButton: "cleanpro-swal-secondary-btn"
                                                        }
                                                    })
                                                    .then((result) => {
                                                        if (result.isConfirmed) {
                                                            mySwal
                                                                .fire({
                                                                    text: t("Processing ..."),
                                                                    allowOutsideClick: false,
                                                                    didOpen: () => {
                                                                        mySwal.showLoading();
                                                                        axios
                                                                            .post(endPoint, sendThis)
                                                                            .then((res) => {
                                                                                if (res.status === 200) {
                                                                                    if (res.data.status === "rm") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        window.location.href = res.data.data;
                                                                                    } else if (res.data.status === "laundro") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        history.push("/thankyou");
                                                                                    } else if (res.data.status === "razer") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        setRazerBody(res.data.data)
                                                                                    } else if (res.data.status === "duitnow") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        setDuitnowUrl(res.data.data)
                                                                                        history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                                    } else if (res.data.status === "ghl") {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.close();
                                                                                        setGhlBody(res.data.data)
                                                                                    } 
                                                                                } else {
                                                                                    e.target.disabled = false;
                                                                                    mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                }
                                                                            })
                                                                            .catch((err) => {
                                                                                mySwal
                                                                                    .fire(t("Error"), t(err.response.data.error), "error")
                                                                                    .then(() => (e.target.disabled = false));
                                                                            });
                                                                    },
                                                                })
                                                                .then(() => {
                                                                    e.target.disabled = false;
                                                                });
                                                        } else {
                                                            e.target.disabled = false;
                                                        }
                                                    });
                                            } else {
                                                e.target.disabled = false;
                                            }
                                        });
                                } else if (user && user.tokenSetting && !user.epayment) {
                                    // Only E-Token
                                    if (parseFloat(user.token) < parseFloat(totalAmount("paymentAmount"))) {
                                        // no token or not enough token
                                        if (STORETYPE === "cleanpro") {
                                            setInsufficient(true);
                                            e.target.disabled = false;
                                        } else {
                                            mySwal
                                                .fire({
                                                    title: t("Insufficient e-Tokens"),
                                                    text: t("Reload your e-token now?"),
                                                    showCancelButton: true,
                                                    cancelButtonText: t("Cancel"),
                                                    confirmButtonText: t("Reload now!"),
                                                    customClass: {
                                                        confirmButton: "reload-token",
                                                    },
                                                })
                                                .then((result) => {
                                                    if (result.isConfirmed && result.value)
                                                        history.push("/user/reload");
                                                    e.target.disabled = false;
                                                });
                                        }
                                    } else {
                                        mySwal
                                            .fire({
                                                title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                                html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${totalAmount("paymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.outlet_name}</strong>?`,
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                customClass: {
                                                    confirmButton: "cleanpro-swal-btn",
                                                    cancelButton: "cleanpro-swal-secondary-btn"
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                const sendThis = {
                                                                    amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                                    paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                                    outletId: machine.outletId,
                                                                    transactionItem: cart,
                                                                    memberId: user.memberId,
                                                                    token: true,
                                                                    voucherId,
                                                                    discountCode,
                                                                    notificationType,
                                                                    email,
                                                                    sms_amount: totalAmount("sms"),
                                                                    number,
                                                                    point_amount: totalAmount("pointAmount"),
                                                                    point_currency: user.point_currency,
                                                                };
                                                                axios
                                                                    .post("/api/user/dng/payment", sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                history.push("/thankyou");
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "duitnow") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setDuitnowUrl(res.data.data)
                                                                                history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            } 
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => (e.target.disabled = false));
                                                                    });

                                                                e.target.disabled = false;
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    }
                                } else {
                                    // Only E-payment
                                    if (machine.duitnow) {
                                        // E-wallet or Duitnow QR code
                                        mySwal
                                            .fire({
                                                title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                                                text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                                                showDenyButton: true,
                                                showCancelButton: true,
                                                cancelButtonText: t("Cancel"),
                                                denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
                                                confirmButtonText: t("Duitnow QR code"),
                                                denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#67b668",
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                customClass: {
                                                    confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn duitnow" : "duitnow",
                                                    denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : "e-wallet",
                                                    cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : "cancel-payment"
                                                },
                                            })
                                            .then((click) => {
                                                if (click.isDenied) {
                                                    // E WALLET PAYMENT (DEFAULT)
                                                    const text = () => {
                                                        if (discountAmount && discountAmount.type === "Flat" && discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)) {
                                                            return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                                        } else {
                                                            return `${t("Pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                                        }
                                                    };

                                                    let sendThis = {
                                                        amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                        paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                        outletId: machine.outletId,
                                                        transactionItem: cart,
                                                        notificationType,
                                                        email,
                                                        sms_amount: totalAmount("sms"),
                                                        number
                                                    };
                                                    let endPoint = "/api/nonuser/dng/payment";
                                                    if (user) {
                                                        sendThis.voucherId = voucherId;
                                                        sendThis.memberId = user.memberId;
                                                        sendThis.discountCode = discountCode;
                                                        sendThis.point_amount = totalAmount("pointAmount");
                                                        sendThis.point_currency = user.point_currency;
                                                        endPoint = "/api/user/dng/payment";
                                                    }

                                                    mySwal
                                                        .fire({
                                                            title: t("Confirmation"),
                                                            text: text(),
                                                            icon: "question",
                                                            showCancelButton: true,
                                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                            customClass: {
                                                                confirmButton: "cleanpro-swal-btn",
                                                                cancelButton: "cleanpro-swal-secondary-btn"
                                                            }
                                                        })
                                                        .then((result) => {
                                                            if (result.isConfirmed) {
                                                                mySwal
                                                                    .fire({
                                                                        text: t("Processing ..."),
                                                                        allowOutsideClick: false,
                                                                        didOpen: () => {
                                                                            mySwal.showLoading();
                                                                            axios
                                                                                .post(endPoint, sendThis)
                                                                                .then((res) => {
                                                                                    if (res.status === 200) {
                                                                                        if (res.data.status === "rm") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            window.location.href = res.data.data;
                                                                                        } else if (res.data.status === "laundro") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            history.push("/thankyou");
                                                                                        } else if (res.data.status === "razer") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setRazerBody(res.data.data)
                                                                                        } else if (res.data.status === "duitnow") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setDuitnowUrl(res.data.data)
                                                                                            history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                                        } else if (res.data.status === "ghl") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setGhlBody(res.data.data)
                                                                                        } 
                                                                                    } else {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                    }
                                                                                })
                                                                                .catch((err) => {
                                                                                    mySwal
                                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                                        .then(() => {
                                                                                            e.target.disabled = false
                                                                                            if (err.response.data.error === "Please log in to make the payment") {
                                                                                                history.push("/");
                                                                                            }
                                                                                        });
                                                                                });
                                                                        },
                                                                    })
                                                                    .then(() => {
                                                                        e.target.disabled = false;
                                                                    });
                                                            } else {
                                                                e.target.disabled = false;
                                                            }
                                                        });
                                                } else if (click.isConfirmed) {
                                                    // Pay with Duitnow QR code
                                                    let sendThis = {
                                                        amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                        paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                        outletId: machine.outletId,
                                                        transactionItem: cart,
                                                        notificationType,
                                                        email,
                                                        sms_amount: totalAmount("sms"),
                                                        number,
                                                        duitnow: true
                                                    };
                                                    let endPoint = "/api/nonuser/dng/payment";
                                                    if (user) {
                                                        sendThis.voucherId = voucherId;
                                                        sendThis.memberId = user.memberId;
                                                        sendThis.discountCode = discountCode;
                                                        sendThis.point_amount = totalAmount("pointAmount");
                                                        sendThis.point_currency = user.point_currency;
                                                        endPoint = "/api/user/dng/payment";
                                                    }

                                                    mySwal
                                                        .fire({
                                                            title: t("Confirmation"),
                                                            html: <Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: totalAmount("paymentAmount") }} to {{ machineName: machine.outlet_name }}?</Trans>,
                                                            icon: "question",
                                                            showCancelButton: true,
                                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                            customClass: {
                                                                confirmButton: "cleanpro-swal-btn",
                                                                cancelButton: "cleanpro-swal-secondary-btn"
                                                            }
                                                        })
                                                        .then((result) => {
                                                            if (result.isConfirmed) {
                                                                mySwal
                                                                    .fire({
                                                                        text: t("Processing ..."),
                                                                        allowOutsideClick: false,
                                                                        didOpen: () => {
                                                                            mySwal.showLoading();
                                                                            axios
                                                                                .post(endPoint, sendThis)
                                                                                .then((res) => {
                                                                                    if (res.status === 200) {
                                                                                        if (res.data.status === "rm") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            window.location.href = res.data.data;
                                                                                        } else if (res.data.status === "laundro") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            history.push("/thankyou");
                                                                                        } else if (res.data.status === "razer") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setRazerBody(res.data.data)
                                                                                        } else if (res.data.status === "duitnow") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setDuitnowUrl(res.data.data)
                                                                                            history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                                        } else if (res.data.status === "ghl") {
                                                                                            e.target.disabled = false;
                                                                                            mySwal.close();
                                                                                            setGhlBody(res.data.data)
                                                                                        } 
                                                                                    } else {
                                                                                        e.target.disabled = false;
                                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                                    }
                                                                                })
                                                                                .catch((err) => {
                                                                                    mySwal
                                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                                        .then(() => {
                                                                                            e.target.disabled = false
                                                                                            if (err.response.data.error === "Please log in to make the payment") {
                                                                                                history.push("/");
                                                                                            }
                                                                                        });
                                                                                });
                                                                        },
                                                                    })
                                                                    .then(() => {
                                                                        e.target.disabled = false;
                                                                    });
                                                            } else {
                                                                e.target.disabled = false;
                                                            }
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            })
                                    } else {
                                        // Only E-wallet
                                        const text = () => {
                                            if (discountAmount && discountAmount.type === "Flat" && discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)) {
                                                return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                            } else {
                                                return `${t("Pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                            }
                                        };

                                        let sendThis = {
                                            amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                            paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                            outletId: machine.outletId,
                                            transactionItem: cart,
                                            notificationType,
                                            email,
                                            sms_amount: totalAmount("sms"),
                                            number
                                        };
                                        let endPoint = "/api/nonuser/dng/payment";
                                        if (user) {
                                            sendThis.voucherId = voucherId;
                                            sendThis.memberId = user.memberId;
                                            sendThis.discountCode = discountCode;
                                            sendThis.point_amount = totalAmount("pointAmount");
                                            sendThis.point_currency = user.point_currency;
                                            endPoint = "/api/user/dng/payment";
                                        }

                                        mySwal
                                            .fire({
                                                title: t("Confirmation"),
                                                text: text(),
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                customClass: {
                                                    confirmButton: "cleanpro-swal-btn",
                                                    cancelButton: "cleanpro-swal-secondary-btn"
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                axios
                                                                    .post(endPoint, sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                history.push("/thankyou");
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "duitnow") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setDuitnowUrl(res.data.data)
                                                                                history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            } 
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => {
                                                                                e.target.disabled = false;
                                                                                if (err.response.data.error === "Please log in to make the payment") {
                                                                                    history.push("/");
                                                                                }
                                                                            });
                                                                    });
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    }
                                }
                            } else {
                                e.target.disabled = false;
                                setOpen(true);
                            }
                        });
                } else {
                    if (user && user.tokenSetting && user.epayment) {
                        // E-payment or E-token
                        mySwal
                            .fire({
                                title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                                text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                                showDenyButton: true,
                                showCancelButton: true,
                                cancelButtonText: machine.duitnow ? t("Duitnow QR code") : t("Cancel"),
                                denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
                                confirmButtonText: STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token"),
                                denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#67b668",
                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                customClass: {
                                    denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : "e-wallet",
                                    confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : "e-token",
                                    cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : "cancel-payment"
                                },
                            })
                            .then((click) => {
                                if (click.isDenied) {
                                    // E WALLET PAYMENT (DEFAULT)
                                    const text = () => {
                                        if (discountAmount && discountAmount.type === "Flat" && discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)) {
                                            return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                        } else {
                                            return `${t("Pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                        }
                                    };

                                    let sendThis = {
                                        amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                        paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                        outletId: machine.outletId,
                                        transactionItem: cart,
                                        notificationType,
                                        email,
                                        sms_amount: totalAmount("sms"),
                                        number
                                    };
                                    let endPoint = "/api/nonuser/dng/payment";
                                    if (user) {
                                        sendThis.voucherId = voucherId;
                                        sendThis.memberId = user.memberId;
                                        sendThis.discountCode = discountCode;
                                        sendThis.point_amount = totalAmount("pointAmount");
                                        sendThis.point_currency = user.point_currency;
                                        endPoint = "/api/user/dng/payment";
                                    }

                                    mySwal
                                        .fire({
                                            title: t("Confirmation"),
                                            text: text(),
                                            icon: "question",
                                            showCancelButton: true,
                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                            customClass: {
                                                confirmButton: "cleanpro-swal-btn",
                                                cancelButton: "cleanpro-swal-secondary-btn"
                                            }
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                mySwal
                                                    .fire({
                                                        text: t("Processing ..."),
                                                        allowOutsideClick: false,
                                                        didOpen: () => {
                                                            mySwal.showLoading();
                                                            axios
                                                                .post(endPoint, sendThis)
                                                                .then((res) => {
                                                                    if (res.status === 200) {
                                                                        if (res.data.status === "rm") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            window.location.href = res.data.data;
                                                                        } else if (res.data.status === "laundro") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            history.push("/thankyou");
                                                                        } else if (res.data.status === "razer") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            setRazerBody(res.data.data)
                                                                        } else if (res.data.status === "duitnow") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            setDuitnowUrl(res.data.data)
                                                                            history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                        } else if (res.data.status === "ghl") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            setGhlBody(res.data.data)
                                                                        } 
                                                                    } else {
                                                                        e.target.disabled = false;
                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                    }
                                                                })
                                                                .catch((err) => {
                                                                    mySwal
                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                        .then(() => (e.target.disabled = false));
                                                                });
                                                        },
                                                    })
                                                    .then(() => {
                                                        e.target.disabled = false;
                                                    });
                                            } else {
                                                e.target.disabled = false;
                                            }
                                        });
                                } else if (click.isConfirmed) {
                                    // TOKEN PAYMENT
                                    if (parseFloat(user.token) < parseFloat(totalAmount("paymentAmount"))) {
                                        // no token or not enough token
                                        if (STORETYPE === "cleanpro") {
                                            setInsufficient(true);
                                            e.target.disabled = false;
                                        } else {
                                            mySwal
                                                .fire({
                                                    title: t("Insufficient e-Tokens"),
                                                    text: t("Reload your e-token now?"),
                                                    showCancelButton: true,
                                                    cancelButtonText: t("Cancel"),
                                                    confirmButtonText: t("Reload now!"),
                                                    customClass: {
                                                        confirmButton: "reload-token",
                                                    },
                                                })
                                                .then((result) => {
                                                    if (result.isConfirmed && result.value)
                                                        history.push("/user/reload");
                                                    e.target.disabled = false;
                                                });
                                        }
                                    } else {
                                        mySwal
                                            .fire({
                                                title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                                html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${totalAmount("paymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.outlet_name}</strong>?`,
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                customClass: {
                                                    confirmButton: "cleanpro-swal-btn",
                                                    cancelButton: "cleanpro-swal-secondary-btn"
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed && result.value) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                const sendThis = {
                                                                    amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                                    paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                                    outletId: machine.outletId,
                                                                    transactionItem: cart,
                                                                    memberId: user.memberId,
                                                                    token: true,
                                                                    voucherId,
                                                                    discountCode,
                                                                    notificationType,
                                                                    email,
                                                                    sms_amount: totalAmount("sms"),
                                                                    number,
                                                                    point_amount: totalAmount("pointAmount"),
                                                                    point_currency: user.point_currency,
                                                                };
                                                                axios
                                                                    .post("/api/user/dng/payment", sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                history.push("/thankyou");
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "duitnow") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setDuitnowUrl(res.data.data)
                                                                                history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            } 
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => (e.target.disabled = false));
                                                                    });

                                                                e.target.disabled = false;
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    }
                                } else if (click.isDismissed && click.dismiss === "cancel" && machine.duitnow) {
                                    // Pay with Duitnow QR code
                                    let sendThis = {
                                        amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                        paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                        outletId: machine.outletId,
                                        transactionItem: cart,
                                        notificationType,
                                        email,
                                        sms_amount: totalAmount("sms"),
                                        number,
                                        duitnow: true
                                    };
                                    let endPoint = "/api/nonuser/dng/payment";
                                    if (user) {
                                        sendThis.voucherId = voucherId;
                                        sendThis.memberId = user.memberId;
                                        sendThis.discountCode = discountCode;
                                        sendThis.point_amount = totalAmount("pointAmount");
                                        sendThis.point_currency = user.point_currency;
                                        endPoint = "/api/user/dng/payment";
                                    }

                                    mySwal
                                        .fire({
                                            title: t("Confirmation"),
                                            html: <Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: totalAmount("paymentAmount") }} to {{ machineName: machine.outlet_name }}?</Trans>,
                                            icon: "question",
                                            showCancelButton: true,
                                            cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                            confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                            reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                            customClass: {
                                                confirmButton: "cleanpro-swal-btn",
                                                cancelButton: "cleanpro-swal-secondary-btn"
                                            }
                                        })
                                        .then((result) => {
                                            if (result.isConfirmed) {
                                                mySwal
                                                    .fire({
                                                        text: t("Processing ..."),
                                                        allowOutsideClick: false,
                                                        didOpen: () => {
                                                            mySwal.showLoading();
                                                            axios
                                                                .post(endPoint, sendThis)
                                                                .then((res) => {
                                                                    if (res.status === 200) {
                                                                        if (res.data.status === "rm") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            window.location.href = res.data.data;
                                                                        } else if (res.data.status === "laundro") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            history.push("/thankyou");
                                                                        } else if (res.data.status === "razer") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            setRazerBody(res.data.data)
                                                                        } else if (res.data.status === "duitnow") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            setDuitnowUrl(res.data.data)
                                                                            history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                        } else if (res.data.status === "ghl") {
                                                                            e.target.disabled = false;
                                                                            mySwal.close();
                                                                            setGhlBody(res.data.data)
                                                                        } 
                                                                    } else {
                                                                        e.target.disabled = false;
                                                                        mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                    }
                                                                })
                                                                .catch((err) => {
                                                                    mySwal
                                                                        .fire(t("Error"), t(err.response.data.error), "error")
                                                                        .then(() => (e.target.disabled = false));
                                                                });
                                                        },
                                                    })
                                                    .then(() => {
                                                        e.target.disabled = false;
                                                    });
                                            } else {
                                                e.target.disabled = false;
                                            }
                                        });
                                } else {
                                    e.target.disabled = false;
                                }
                            });
                    } else if (user && user.tokenSetting && !user.epayment) {
                        // Only E-Token
                        if (parseFloat(user.token) < parseFloat(totalAmount("paymentAmount"))) {
                            // no token or not enough token
                            if (STORETYPE === "cleanpro") {
                                setInsufficient(true);
                                e.target.disabled = false;
                            } else {
                                mySwal
                                    .fire({
                                        title: t("Insufficient e-Tokens"),
                                        text: t("Reload your e-token now?"),
                                        showCancelButton: true,
                                        cancelButtonText: t("Cancel"),
                                        confirmButtonText: t("Reload now!"),
                                        customClass: {
                                            confirmButton: "reload-token",
                                        },
                                    })
                                    .then((result) => {
                                        if (result.isConfirmed && result.value)
                                            history.push("/user/reload");
                                        e.target.disabled = false;
                                    });
                            }
                        } else {
                            mySwal
                                .fire({
                                    title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                                    html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${totalAmount("paymentAmount")}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.outlet_name}</strong>?`,
                                    icon: "question",
                                    showCancelButton: true,
                                    cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                    confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                    reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                    confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                    customClass: {
                                        confirmButton: "cleanpro-swal-btn",
                                        cancelButton: "cleanpro-swal-secondary-btn"
                                    }
                                })
                                .then((result) => {
                                    if (result.isConfirmed && result.value) {
                                        mySwal
                                            .fire({
                                                text: t("Processing ..."),
                                                allowOutsideClick: false,
                                                didOpen: () => {
                                                    mySwal.showLoading();
                                                    const sendThis = {
                                                        amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                                        paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                                        outletId: machine.outletId,
                                                        transactionItem: cart,
                                                        memberId: user.memberId,
                                                        token: true,
                                                        voucherId,
                                                        discountCode,
                                                        notificationType,
                                                        email,
                                                        sms_amount: totalAmount("sms"),
                                                        number,
                                                        point_amount: totalAmount("pointAmount"),
                                                        point_currency: user.point_currency,
                                                    };
                                                    axios
                                                        .post("/api/user/dng/payment", sendThis)
                                                        .then((res) => {
                                                            if (res.status === 200) {
                                                                if (res.data.status === "rm") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    window.location.href = res.data.data;
                                                                } else if (res.data.status === "laundro") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    history.push("/thankyou");
                                                                } else if (res.data.status === "razer") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setRazerBody(res.data.data)
                                                                } else if (res.data.status === "duitnow") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setDuitnowUrl(res.data.data)
                                                                    history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                } else if (res.data.status === "ghl") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setGhlBody(res.data.data)
                                                                } 
                                                            } else {
                                                                e.target.disabled = false;
                                                                mySwal.fire(t("Error"), t("Response Error"), "error");
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            mySwal
                                                                .fire(t("Error"), t(err.response.data.error), "error")
                                                                .then(() => (e.target.disabled = false));
                                                        });

                                                    e.target.disabled = false;
                                                },
                                            })
                                            .then(() => {
                                                e.target.disabled = false;
                                            });
                                    } else {
                                        e.target.disabled = false;
                                    }
                                });
                        }
                    } else {
                        // Only E-payment
                        if (machine.duitnow) {
                            // E-wallet or Duitnow QR code
                            mySwal
                                .fire({
                                    title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                                    text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                                    showDenyButton: true,
                                    showCancelButton: true,
                                    cancelButtonText: t("Cancel"),
                                    denyButtonText: STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet"),
                                    confirmButtonText: t("Duitnow QR code"),
                                    denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#67b668",
                                    confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                    customClass: {
                                        confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn duitnow" : "duitnow",
                                        denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : "e-wallet",
                                        cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : "cancel-payment"
                                    },
                                })
                                .then((click) => {
                                    if (click.isDenied) {
                                        // E WALLET PAYMENT (DEFAULT)
                                        const text = () => {
                                            if (discountAmount && discountAmount.type === "Flat" && discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)) {
                                                return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                            } else {
                                                return `${t("Pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                            }
                                        };

                                        let sendThis = {
                                            amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                            paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                            outletId: machine.outletId,
                                            transactionItem: cart,
                                            notificationType,
                                            email,
                                            sms_amount: totalAmount("sms"),
                                            number
                                        };
                                        let endPoint = "/api/nonuser/dng/payment";
                                        if (user) {
                                            sendThis.voucherId = voucherId;
                                            sendThis.memberId = user.memberId;
                                            sendThis.discountCode = discountCode;
                                            sendThis.point_amount = totalAmount("pointAmount");
                                            sendThis.point_currency = user.point_currency;
                                            endPoint = "/api/user/dng/payment";
                                        }

                                        mySwal
                                            .fire({
                                                title: t("Confirmation"),
                                                text: text(),
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                customClass: {
                                                    confirmButton: "cleanpro-swal-btn",
                                                    cancelButton: "cleanpro-swal-secondary-btn"
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                axios
                                                                    .post(endPoint, sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                history.push("/thankyou");
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "duitnow") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setDuitnowUrl(res.data.data)
                                                                                history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            } 
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => {
                                                                                e.target.disabled = false
                                                                                if (err.response.data.error === "Please log in to make the payment") {
                                                                                    history.push("/");
                                                                                }
                                                                            });
                                                                    });
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    } else if (click.isConfirmed) {
                                        // Pay with Duitnow QR code
                                        let sendThis = {
                                            amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                            paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                            outletId: machine.outletId,
                                            transactionItem: cart,
                                            notificationType,
                                            email,
                                            sms_amount: totalAmount("sms"),
                                            number,
                                            duitnow: true
                                        };
                                        let endPoint = "/api/nonuser/dng/payment";
                                        if (user) {
                                            sendThis.voucherId = voucherId;
                                            sendThis.memberId = user.memberId;
                                            sendThis.discountCode = discountCode;
                                            sendThis.point_amount = totalAmount("pointAmount");
                                            sendThis.point_currency = user.point_currency;
                                            endPoint = "/api/user/dng/payment";
                                        }

                                        mySwal
                                            .fire({
                                                title: t("Confirmation"),
                                                html: <Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: totalAmount("paymentAmount") }} to {{ machineName: machine.outlet_name }}?</Trans>,
                                                icon: "question",
                                                showCancelButton: true,
                                                cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                                confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                                reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                                customClass: {
                                                    confirmButton: "cleanpro-swal-btn",
                                                    cancelButton: "cleanpro-swal-secondary-btn"
                                                }
                                            })
                                            .then((result) => {
                                                if (result.isConfirmed) {
                                                    mySwal
                                                        .fire({
                                                            text: t("Processing ..."),
                                                            allowOutsideClick: false,
                                                            didOpen: () => {
                                                                mySwal.showLoading();
                                                                axios
                                                                    .post(endPoint, sendThis)
                                                                    .then((res) => {
                                                                        if (res.status === 200) {
                                                                            if (res.data.status === "rm") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                window.location.href = res.data.data;
                                                                            } else if (res.data.status === "laundro") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                history.push("/thankyou");
                                                                            } else if (res.data.status === "razer") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setRazerBody(res.data.data)
                                                                            } else if (res.data.status === "duitnow") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setDuitnowUrl(res.data.data)
                                                                                history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                            } else if (res.data.status === "ghl") {
                                                                                e.target.disabled = false;
                                                                                mySwal.close();
                                                                                setGhlBody(res.data.data)
                                                                            } 
                                                                        } else {
                                                                            e.target.disabled = false;
                                                                            mySwal.fire(t("Error"), t("Response Error"), "error");
                                                                        }
                                                                    })
                                                                    .catch((err) => {
                                                                        mySwal
                                                                            .fire(t("Error"), t(err.response.data.error), "error")
                                                                            .then(() => {
                                                                                e.target.disabled = false
                                                                                if (err.response.data.error === "Please log in to make the payment") {
                                                                                    history.push("/");
                                                                                }
                                                                            });
                                                                    });
                                                            },
                                                        })
                                                        .then(() => {
                                                            e.target.disabled = false;
                                                        });
                                                } else {
                                                    e.target.disabled = false;
                                                }
                                            });
                                    } else {
                                        e.target.disabled = false;
                                    }
                                })
                        } else {
                            // Only E-wallet
                            const text = () => {
                                if (discountAmount && discountAmount.type === "Flat" && discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)) {
                                    return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                } else {
                                    return `${t("Pay")} ${currency(machine.country)} ${totalAmount("paymentAmount")} ${t("to")} ${machine.outlet_name}?`;
                                }
                            };

                            let sendThis = {
                                amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
                                paymentAmount: totalAmount("paymentAmountWithoutSMS"),
                                outletId: machine.outletId,
                                transactionItem: cart,
                                notificationType,
                                email,
                                sms_amount: totalAmount("sms"),
                                number
                            };
                            let endPoint = "/api/nonuser/dng/payment";
                            if (user) {
                                sendThis.voucherId = voucherId;
                                sendThis.memberId = user.memberId;
                                sendThis.discountCode = discountCode;
                                sendThis.point_amount = totalAmount("pointAmount");
                                sendThis.point_currency = user.point_currency
                                endPoint = "/api/user/dng/payment";
                            }

                            mySwal
                                .fire({
                                    title: t("Confirmation"),
                                    text: text(),
                                    icon: "question",
                                    showCancelButton: true,
                                    cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                                    confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                                    reverseButtons: STORETYPE === "cleanpro" ? true : false,
                                    confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                                    customClass: {
                                        confirmButton: "cleanpro-swal-btn",
                                        cancelButton: "cleanpro-swal-secondary-btn"
                                    }
                                })
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        mySwal
                                            .fire({
                                                text: t("Processing ..."),
                                                allowOutsideClick: false,
                                                didOpen: () => {
                                                    mySwal.showLoading();
                                                    axios
                                                        .post(endPoint, sendThis)
                                                        .then((res) => {
                                                            if (res.status === 200) {
                                                                if (res.data.status === "rm") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    window.location.href = res.data.data;
                                                                } else if (res.data.status === "laundro") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    history.push("/thankyou");
                                                                } else if (res.data.status === "razer") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setRazerBody(res.data.data)
                                                                } else if (res.data.status === "duitnow") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setDuitnowUrl(res.data.data)
                                                                    history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                                                                } else if (res.data.status === "ghl") {
                                                                    e.target.disabled = false;
                                                                    mySwal.close();
                                                                    setGhlBody(res.data.data)
                                                                } 
                                                            } else {
                                                                e.target.disabled = false;
                                                                mySwal.fire(t("Error"), t("Response Error"), "error");
                                                            }
                                                        })
                                                        .catch((err) => {
                                                            mySwal
                                                                .fire(t("Error"), t(err.response.data.error), "error")
                                                                .then(() => {
                                                                    e.target.disabled = false;
                                                                    if (err.response.data.error === "Please log in to make the payment") {
                                                                        history.push("/");
                                                                    }
                                                                });
                                                        });
                                                },
                                            })
                                            .then(() => {
                                                e.target.disabled = false;
                                            });
                                    } else {
                                        e.target.disabled = false;
                                    }
                                });
                        }
                    }
                }
            }
        }
    }

    const useVoucher = () => {
        if (user) {
            let id = selectVoucherId
            if (id) {
                let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
                setVoucherId(foundVoucher.id)
                setDiscountAmount({
                    type: foundVoucher.type,
                    amount: foundVoucher.amount
                })
                setOpen(false);
            }
        }
    };

    const chooseVoucher = (voucherId) => {
        if (user) {
            let id = voucherId
            if (id) {
                let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
                setVoucherId(foundVoucher.id)
                setDiscountAmount({
                    type: foundVoucher.type,
                    amount: foundVoucher.amount
                })
                setOpen(false);
            }
        }
    }

    const renderVouchers = () => {
        if (user) {
            if (user.vouchers && user.vouchers.length) {
                return user.vouchers
                    .filter((voucher) => voucher.available && voucher.package_use)
                    .map((voucher) => {
                        let currentVoucherAmount = voucher.amount
                        return (
                            <div key={voucher.id} className="payment-voucher">
                                {STORETYPE === "cleanpro" ? (
                                    <div
                                        style={{ borderRadius: "0" }}
                                        className="voucher my-3"
                                        key={voucher.id}
                                    >
                                        <div
                                            className="voucher-content center-content"
                                            style={{
                                                color: "black",
                                                position: "relative",
                                                zIndex: "99",
                                                marginTop: "1em",
                                            }}
                                        >
                                            <div
                                                className="d-flex"
                                                style={{
                                                    width: "50%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    marginLeft: "1em",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <h2 className="font-weight-bold">
                                                    {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                                                </h2>

                                                <small>{voucher.description}</small>

                                                {voucher.expired_date ? (
                                                    <h6 style={{ color: "grey" }} className="m-0 p-0">
                                                        <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                                                    </h6>
                                                ) : null}

                                            </div>
                                            <div
                                                style={{ width: "30%" }}
                                                className="center-content"
                                            >
                                                <input
                                                    onChange={() => setSelectVoucherId(voucher.id)}
                                                    style={{
                                                        border: "1px solid grey",
                                                        cursor: "pointer",
                                                        opacity: "1",
                                                        pointerEvents: "fill",
                                                        position: "relative",
                                                    }}
                                                    type="radio"
                                                    name="paymentVoucher"
                                                    id={voucher.id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={voucher.id}
                                        onClick={() => chooseVoucher(voucher.id)}
                                        className="list-group-item"
                                    >
                                        <p>{voucher.title}</p>
                                        <h6>
                                            {t("Discount")} :- {voucher.type === "Flat" ? currency(machine.country) : null}
                                            {voucher.amount}
                                            {voucher.type === "Rate" ? "%" : null}
                                        </h6>
                                    </div>
                                )}
                            </div>
                        );
                    });
            } else {
                return (
                    <div className="mt-4">
                        <p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    return (
        <div>
            {
                list.map((pack, index) => {
                    return (
                        <div key={index} className="payment-section">
                            <div className="d-flex justify-content-between">
                                <p><b>{t("Pack")} {index + 1}</b></p>
                                <i className="bi bi-trash3-fill" style={{ color: "red", fontSize: "20px" }} onClick={() => deleteItem(pack.sequence)}></i>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p><b>{t("Size")}</b></p>
                                <p>{pack.size}</p>
                            </div>
                            {
                                pack.wash ? (
                                    <div>
                                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                        <p><b>{t("Wash")}</b></p>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Mode")}</p>
                                            <p>{t(pack.washMode)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Capacity")}</p>
                                            <p>{pack.washCapacity}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Price")}</p>
                                            <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.washPrice} ${t("credit")}` : `${currency(machine.country)} ${pack.washPrice}`}</b></p>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                pack.dry ? (
                                    <div>
                                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                        <p><b>{t("Dry")}</b></p>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Duration")}</p>
                                            <p>{pack.dryDuration} {t("min")}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Capacity")}</p>
                                            <p>{pack.dryCapacity}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Price")}</p>
                                            <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.dryPrice} ${t("credit")}` : `${currency(machine.country)} ${pack.dryPrice}`}</b></p>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                pack.fold ? (
                                    <div>
                                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                        <p><b>{t("Fold")}</b></p>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Price")}</p>
                                            <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.foldPrice} ${t("credit")}` : `${currency(machine.country)} ${pack.foldPrice}`}</b></p>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })
            }

            <div>
                <div className="payment-section payment-notification-container">
                    <p><b>{t("Select how you want to receive a notification when your laundry is finished")}:</b></p>
                    <div className="d-flex justify-content-around form-group pt-1">
                        <label htmlFor="radio2">
                            <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                            <span style={{ color: "black" }}>{t("Email")}</span>
                        </label>

                        <label htmlFor="radio3">
                            <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                            <span style={{ color: "black" }}>SMS ({STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("sms")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("sms")}`}/SMS)</span>
                        </label>
                    </div>

                    {
                        notificationType === "Email" ? (
                            <div className="form-group">
                                <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required placeholder={t("Email Address")} />
                            </div>
                        ) : null
                    }

                    {
                        notificationType === "SMS" ? (
                            <div className="form-group">
                                <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required placeholder={t("Phone Number")} />
                            </div>
                        ) : null
                    }
                </div>
            </div>

            <div className="payment-section">
                {
                    user ? (
                        <div>
                            {
                                discountCode || !voucherId ? (
                                    <div>
                                        {
                                            STORETYPE === "cleanpro" ? (
                                                <div
                                                    id="cleanpro-input-discountcode"
                                                    onClick={(e) => setDiscountCodeModal(true)}
                                                    className="checkout-payment py-2"
                                                >
                                                    <i className="bi bi-percent text-center" style={{ width: "32px" }}></i>
                                                    <p className="ml-1">
                                                        {discountCode ? discountCode : t("Discount Code")}
                                                    </p>
                                                    <p>
                                                        <i className="arrow right"></i>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="list-group payment-list-group">
                                                    <div className="list-group-item payment-list-group-item">
                                                        <div>
                                                            <p className="text-secondary">{t("DISCOUNT CODE")}</p>
                                                        </div>

                                                        <div>
                                                            <button
                                                                id="select-voucher"
                                                                type="button"
                                                                onClick={(e) => setDiscountCodeModal(true)}
                                                                className="text-secondary"
                                                                style={{
                                                                    border: "none",
                                                                    backgroundColor: "white",
                                                                    outline: "none",
                                                                    fontWeight: "bold",
                                                                    paddingRight: "0px",
                                                                    fontSize: "large",
                                                                }}
                                                            >
                                                                {discountCode ? discountCode : t("Enter code >")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                voucherId || !discountCode ? (
                                    <div>
                                        {
                                            STORETYPE === "cleanpro" ? (
                                                <div
                                                    id="cleanpro-select-voucher"
                                                    onClick={(e) => setOpen(true)}
                                                    className="checkout-payment py-2"
                                                >
                                                    <img
                                                        className="fit-contain"
                                                        style={{ width: "32px" }}
                                                        src={ticket}
                                                        alt=""
                                                    />
                                                    <p className="ml-1">
                                                        {voucherId ? t("1 voucher selected") : t("No voucher selected")}
                                                    </p>
                                                    <p>
                                                        <i className="arrow right"></i>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="list-group payment-list-group">
                                                    <div className="list-group-item payment-list-group-item">
                                                        <div>
                                                            <p className="text-secondary">{t("VOUCHER")}</p>
                                                        </div>

                                                        <div>
                                                            <button
                                                                id="select-voucher"
                                                                type="button"
                                                                onClick={(e) => setOpen(true)}
                                                                className="text-secondary"
                                                                style={{
                                                                    border: "none",
                                                                    backgroundColor: "white",
                                                                    outline: "none",
                                                                    fontWeight: "bold",
                                                                    paddingRight: "0px",
                                                                    fontSize: "large",
                                                                }}
                                                            >
                                                                {voucherId ? discountText() : t("Select Voucher >")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                STORETYPE === "cleanpro" && user && user.tokenSetting ? (
                                    <div
                                        id="cleanpro-reload-token"
                                        // onClick={() => history.push("/user/reload")}
                                        className="checkout-payment py-2"
                                    >
                                        <img style={{ width: "32px" }} src={creditBear} alt="" />
                                        <p className="ml-1">
                                            {user.token} {t("credits")}
                                        </p>
                                        {/* <p>
                                            <i className="arrow right"></i>
                                        </p> */}
                                    </div>
                                ) : null
                            }
                            {
                                STORETYPE === "cleanpro" && user && user.loyalty_point ? (
                                    <div id="cleanpro-point" className="d-flex justify-content-between py-2">
                                        <div className="d-flex align-items-center">
                                            <img style={{ width: "32px" }} src={cleanproCreditIcon} alt="" />
                                            <p className="ml-1">
                                                {user.point} {user.loyalty_point_name}
                                            </p>
                                        </div>
                                        <div className="toggle-switch">
                                            <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                                            <label className="toggle-switch-label" htmlFor="point">
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                STORETYPE !== "cleanpro" && user && user.loyalty_point ? (
                                    <div className="list-group payment-list-group">
                                        <div className="list-group-item payment-list-group-item">
                                            <div>
                                                <p className="text-secondary">{user.loyalty_point_name} ({user.point})</p>
                                            </div>

                                            <div className="toggle-switch">
                                                <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                                                <label className="toggle-switch-label" htmlFor="point">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                            <div className="d-flex justify-content-between py-2">
                                <p style={{ fontWeight: "500" }}>{t("Total")}</p>
                                <p>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("amount")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("amount")}`}</p>
                            </div>
                            {
                                voucherId || discountCode ? (
                                    <div className="d-flex justify-content-between py-2">
                                        <p style={{ fontWeight: "500" }}>{t("Offers")}</p>
                                        <p>-{discountText()}</p>
                                    </div>
                                ) : null
                            }
                            {
                                point ? (
                                    <div className="d-flex justify-content-between py-2">
                                        <p style={{ fontWeight: "500" }}>{user.loyalty_point_name}</p>
                                        <p>-{totalAmount("pointAmount")} {user.loyalty_point_name} ({STORETYPE === "cleanpro" && user.tokenSetting ? "" : currency(machine.country)} {totalAmount("pointValue")} {STORETYPE === "cleanpro" && user.tokenSetting ? t("credit") : ""})</p>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                {
                    notificationType === "SMS" ? (
                        <div className="d-flex justify-content-between py-2">
                            <p style={{ fontWeight: "500" }}>{t("SMS")}</p>
                            <p>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("sms")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("sms")}`}</p>
                        </div>
                    ) : null
                }
                <div className="d-flex justify-content-between py-2">
                    <p style={{ fontWeight: "500" }}>{t("Amount to Pay")}</p>
                    <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("paymentAmount")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("paymentAmount")}`}</b></p>
                </div>
            </div>

            <div className="px-3 pb-3">
                <button
                    id="pay-dng"
                    className="btn form-control big-button"
                    onClick={(e) => pay(e)}
                >
                    {t("Pay now")}
                </button>
            </div>

            <div className="px-3 pb-3">
                <button
                    id="add-more-dng"
                    onClick={(e) => setPage("Size")}
                    className="btn form-control secondary-button"
                    style={{ height: "50px" }}
                >
                    {t("Add more")}
                </button>
            </div>

            {/* CUSTOM VOUCHER MODAL */}
            {user ? (
                <div>
                    <div
                        onClick={(e) => setOpen(false)}
                        className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}
                    ></div>
                    <div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
                        <div className="custom-modal-header">
                            {voucherId ? (
                                <button
                                    id="remove-voucher"
                                    onClick={(e) => removeDiscount()}
                                    type="button"
                                    className="custom-modal-title m-0 p-0"
                                    style={{
                                        color: "red",
                                        border: "none",
                                        backgroundColor: "white",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                >
                                    <i className="bi bi-slash-circle"></i> {t("Remove Voucher")}
                                </button>
                            ) : (
                                <h6 className="custom-modal-title">
                                    {STORETYPE === "cleanpro" ? (
                                        t("Select voucher to use")
                                    ) : (
                                        <>
                                            <i className="bi bi-tags-fill"></i> {t("Select Your Voucher")}
                                        </>
                                    )}
                                </h6>
                            )}
                        </div>

                        <div className="custom-modal-body">
                            <ul className="list-group list-group-flush px-4">
                                {renderVouchers()}
                            </ul>
                        </div>
                        {
                            STORETYPE === "cleanpro" ? (
                                <button id="use-voucher" onClick={useVoucher} className="btn big-button" style={{ width: "90%" }}>
                                    {t("Use voucher")}
                                </button>
                            ) : null
                        }
                    </div>
                </div>
            ) : null}

            {
                razerBody ? (
                    <div>
                        <form action={razerBody.paymentDomain} method="post">
                            <input type="hidden" name="amount" value={razerBody.amount} />
                            <input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
                            <input type="hidden" name="orderid" value={razerBody.orderid} />
                            {razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
                            {razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
                            {razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
                            <input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
                            <input type="hidden" name="country" value={razerBody.country} />
                            <input type="hidden" name="vcode" value={razerBody.vcode} />
                            <input type="hidden" name="currency" value={razerBody.currency} />
                            <input type="hidden" name="returnurl" value={razerBody.returnurl} />
                            <input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
                            <input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

                            <input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
                        </form>
                    </div>
                ) : null
            }

            {duitnowUrl ? <a className="btn btn-primary" ref={duitnowDownloadClick} href={`${apiUrl}/api/user/downloadDuitnowQr?url=${duitnowUrl}`} download={`duitnow_${dateFormat(new Date())}_${timeFormat(new Date())}.png`} hidden>{t("Download")}</a> : null}

            {
				ghlBody ? (
					<div>
						<form name="frmPayment" method="post" action={ghlBody.frmPayment}>
							<input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
							<input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
							<input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
							<input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
							<input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
							<input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc}/>
							<input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL}/>
							<input type="hidden" name="MerchantCallbackURL" value={ghlBody.MerchantCallbackURL}/>
							<input type="hidden" name="Amount" value={ghlBody.Amount} />
							<input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
							<input type="hidden" name="CustIP" value={ghlBody.CustIP} />
							<input type="hidden" name="CustName" value={ghlBody.CustName} />
							<input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
							<input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
							<input type="hidden" name="HashValue" value={ghlBody.HashValue} />
							<input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

							<input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
						</form>

					</div>
				) : null
			}

            <Modal
                show={discountCodeModal}
                onHide={(e) => setDiscountCodeModal(false)}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Enter Discount Code")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={checkDiscountCode}>
                            {discountCode ? (
                                <div className="form-group text-left">
                                    <label htmlFor="code">{t("Discount Code")}</label>
                                    <input
                                        type="text"
                                        id="code"
                                        name="code"
                                        value={discountCode}
                                        className="form-control browser-default"
                                        readOnly
                                    />
                                </div>
                            ) : (
                                <div className="form-group text-left">
                                    <label htmlFor="code">{t("Discount Code")}</label>
                                    <input
                                        type="text"
                                        id="code"
                                        name="code"
                                        value={discountCodeInput}
                                        className="form-control browser-default"
                                        onChange={(e) => setDiscountCodeInput(e.target.value.toUpperCase())}
                                        required
                                    />
                                </div>
                            )}

                            <div className="text-center pt-3">
                                {loading ? (
                                    <div className="text-center">
                                        <div
                                            className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                                            role="status"
                                        >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : discountCode ? (
                                    <input
                                        id="remove-discount-code"
                                        type="button"
                                        value={t("Remove Code")}
                                        onClick={() => removeDiscount()}
                                        className="btn btn-primary mx-2"
                                    />
                                ) : (
                                    <input
                                        id="submit-discount-code"
                                        type="submit"
                                        value={t("Submit")}
                                        className="btn btn-primary mx-2"
                                    />
                                )}
                                <button
                                    id="close-discount-code"
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={(e) => setDiscountCodeModal(false)}
                                >
                                    {t("Close")}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={insufficient}
                onHide={(e) => setInsufficient(false)}
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className={theme}>
                        <img
                            className="d-block"
                            src={insufficientToken}
                            style={{ width: "64px", margin: "0 auto" }}
                            alt=""
                        />
                        <h2 className="align-center my-3"> {t("Insufficient balance")}</h2>
                        <p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p>
                        <div className="d-flex justify-content-around pt-3">
                            <button id="cleanpro-cancel-topup" onClick={() => setInsufficient(false)} style={{ height: "50px", width: "40%" }} className="btn secondary-button">
                                {t("Back")}
                            </button>
                            <button id="cleanpro-topup-now" onClick={() => history.push("/user/reload")} className="btn big-button" style={{ width: "50%" }}>
                                {t("Topup now")}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Payment;