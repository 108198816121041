import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import PaymentDone from '../../../../assets/images/logo/PaymentDone.png';
import './Responsive.css'

import Faq from './Faq.js'

const ThankYou = ({ setPage, data, machineType, machine }) => {
    const { t } = useTranslation();
    const [showFaq, setShowFaq] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setPage("Start")
        }, 15000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div id="thank-you-screen" className="d-flex flex-column align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#E3F2FF", padding: "40px", position: "relative" }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt='Home Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt='FAQ Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(!showFaq)}
                    />
                    <img
                        src={BackLogo}
                        alt='Back Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )}
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <div className="content d-flex flex-column align-items-center justify-content-center" style={{ height: '100%', textAlign: 'center' }}>
                <img
                    src={PaymentDone}
                    alt='Payment Done'
                    style={{ height: '200px', marginBottom: '20px' }}
                />

                <h2 style={{ color: '#004FC1', fontSize: '60px', fontWeight: '600', marginBottom: '20px' }}>
                    {t('Thank you for payment!')}
                </h2>

                {machineType === 'Vending Machine' ? (
                    <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0' }}>
                        {t("Please select your items at the vending machine")}
                    </p>
                ) : machineType === "Washer" || (machineType === "Dryer" && !machine?.running) ? (
                    <p style={{ color: '#000', fontSize: '40px', fontWeight: '600', lineHeight: '1.4', margin: '20px 0' }}>
                        {t("Please choose the temperature on the machine and press")} <span style={{ color: "red" }}>{t("START")}</span>
                    </p>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
    };
};

export default connect(mapStateToProps, null)(ThankYou);
