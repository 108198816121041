/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import imgbombomsignup from '../../../../assets/images/logo/img-bombom-signup.png';
import cleanprologo from '../../../../assets/images/logo/Logo_Cleanproplus-white.png';
import { changeLanguage } from "../../../../redux/actions/userActions"
import { connect } from "react-redux"
import './Responsive.css';

const StartScreen = ({ setPage, language, changeLanguage, data, checkReaderStatus }) => {
    const { t } = useTranslation();

    const [regVoucherSetting, setRegVoucherSetting] = useState(null);

    useEffect(() => {
        if (data && data.voucherSetting) {
            const registerVoucherSetting = data.voucherSetting.filter(setting => setting.type === "Register")[0]
            if (registerVoucherSetting) {
                if (registerVoucherSetting.voucher_eligible) {
                    setRegVoucherSetting(registerVoucherSetting)
                }
            }
        }
    }, []);

    const start = () => {
        checkReaderStatus()
        setPage('Option')
    }

    return (
        <div id="start-screen" className="d-flex flex-column justify-content-between align-items-center" style={{ height: "1920px", width: "1080px", backgroundColor: "#004FC1" }} onClick={() => start()}>
            <div className="content d-flex flex-column align-items-center" style={{ marginTop: '180px' }}>
                <div className="logo-placeholder" style={{ width: '400px', height: '400px', marginBottom: '80px' }}>
                    <img
                        src={imgbombomsignup}
                        alt="cleanproWelcomeLogo"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', display: 'block' }}
                    />
                </div>
                <div className="logo-placeholder" style={{ width: '600px', marginBottom: '80px' }}>
                    <img
                        src={cleanprologo}
                        alt="Cleanpro Plus Logo"
                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', display: 'block' }}
                    />
                </div>
                <div
                    className="text-white"
                    style={{
                        fontSize: '60px',
                        fontWeight: '600',
                        cursor: 'pointer',
                        marginBottom: '50px'
                    }}
                >
                    {t('Touch to Start')}
                </div>

                {
                    regVoucherSetting ? (
                        <>
                            <div
                                className="text-white"
                                style={{
                                    fontSize: '60px',
                                    fontWeight: '700',
                                }}
                            >
                                {t('New Users')}:
                            </div>

                            <div
                                className="text-white"
                                style={{
                                    fontSize: '60px',
                                    fontWeight: '700',
                                    marginBottom: '100px'
                                }}
                            >
                                {
                                    regVoucherSetting.voucher_type === "Flat"
                                        ? <Trans i18nKey="Get {{ voucher_amount }} FREE Credits!">Get {{ voucher_amount: regVoucherSetting.voucher_amount }} FREE Credits!</Trans>
                                        : <Trans i18nKey="Get {{ voucher_amount }}% FREE!">Get {{ voucher_amount: regVoucherSetting.voucher_amount }}% FREE!</Trans>
                                }
                            </div>
                        </>
                    ) : null
                }

                <div className="reminder-text text-white" style={{ textAlign: "center", fontWeight: '400' }}>
                    <p style={{ fontSize: "40px" }}><b>{t("Reminder")}:</b></p>
                    <p style={{ fontSize: '36px' }}>{t("Load your clothes into the washing machine & note the machine number")}</p>
                </div>
            </div>

            <div className="language-options d-flex justify-content-center" style={{
                position: "absolute",
                bottom: "250px",
                left: "50%",
                transform: "translateX(-50%)",
                gap: "10px",
            }}>
                <Button
                    variant="outline-light"
                    active={language === "en"}
                    style={{
                        padding: '10px 0',
                        fontSize: '36px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        borderWidth: '2px',
                        width: '120px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        changeLanguage('en');
                    }}
                >
                    EN
                </Button>
                <Button
                    variant="outline-light"
                    active={language === "ms"}
                    style={{
                        padding: '10px 0',
                        fontSize: '36px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        borderWidth: '2px',
                        width: '120px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        changeLanguage('ms');
                    }}
                >
                    BM
                </Button>
                <Button
                    variant="outline-light"
                    active={language === "zh"}
                    style={{
                        padding: '10px 0',
                        fontSize: '36px',
                        fontWeight: '500',
                        borderRadius: '30px',
                        borderWidth: '2px',
                        width: '120px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        changeLanguage('zh');
                    }}
                >
                    中文
                </Button>
            </div>
            <h1 className="text-white" style={{ fontSize: '30px', fontWeight: '400', marginBottom: '10px' }}>{t('T&C Apply')}</h1>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        language: state.user.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartScreen);
