import React from 'react';
import { useTranslation } from 'react-i18next';

import cleanprologo from '../../../../assets/images/logo/Logo_Cleanproplus-white.png';
import comingsoonlogo from '../../../../assets/images/logo/cleanpro-comingsoon-bear-logo.png';

const ComingSoon = () => {
    const { t } = useTranslation();

    return (
        <div
            id="coming-soon-screen"
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "1920px", width: "1080px", backgroundColor: "#004FC1" }}
        >
            <div
                className="content d-flex flex-column align-items-center"
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
            >
                <div
                    className="logo-placeholder"
                    style={{ width: '400px', marginTop: '0', marginBottom: '40px' }}
                >
                    <img
                        src={cleanprologo}
                        alt="Cleanpro Logo"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            marginBottom: '50px'
                        }}
                    />
                </div>
                <img
                    src={comingsoonlogo}
                    alt="Bombom Signup Logo"
                    style={{
                        width: '900px',
                        objectFit: 'contain',
                        display: 'block'
                    }}
                />

                <div
                    className="text-white"
                    style={{
                        fontSize: '100px',
                        fontWeight: '850',
                        marginBottom: '30px',
                        textAlign: 'center',
                    }}
                >
                    {t('COMING')}<br />{t('SOON')}
                </div>

                <div
                    className="text-white"
                    style={{
                        fontSize: '55px',
                        fontWeight: '600',
                        textAlign: 'center',
                    }}
                >
                    {t('Stay Tuned!')}
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;