import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { saveOperatorId } from "../../../redux/actions/userActions";
import { fetchTheme } from "../../../redux/actions/themeAction";

import { useTranslation } from 'react-i18next';

const mySwal = withReactContent(Swal);

const CheckOperator = ({ saveOperatorId, fetchTheme, setOutletId }) => {

    const [loading, setLoading] = useState(true);
    const [outletList, setOutletList] = useState([]);
    const [outlet, setOutlet] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        axios.get('/api/nonuser/getOutletList')
            .then(res => {
                setOutletList(res.data.data);
                setLoading(false)
            })
            .catch(err => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error")
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        const selectedOutlet = outletList.filter(o => o.id === outlet)[0];

        if (process.env.REACT_APP_STORETYPE !== "cuci" && process.env.REACT_APP_STORETYPE !== "cleanpro") {
            fetchTheme({ operatorCode: selectedOutlet.adminId });
        }
        setOutletId(selectedOutlet.id)
        saveOperatorId(selectedOutlet.adminId);
    }

    return (
        <div className="px-5 mt-5">
            <div className="text-center mb-4">
                <h3 className="font-weight-bold m-0 p-0">{t("WELCOME")}</h3>
            </div>

            <div>
                <form onSubmit={handleSubmit}>
                    <div className="px-4">
                        <div>
                            <label className="form-label" htmlFor="outlet" id="outlet-label">
                                {t("Outlet")}
                            </label>
                            <select
                                className="form-select"
                                name="outlet"
                                id="outlet"
                                onChange={e => setOutlet(e.target.value)}
                                value={outlet}
                                required
                            >
                                <option value="" disabled>
                                    {t("Select an outlet")}
                                </option>
                                {
                                    outletList.map((outlet, i) => <option key={i} value={outlet.id}>{outlet.outlet_fullname}</option>)
                                }
                            </select>
                        </div>

                        <div className="mt-5">
                            {
                                loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <input id="selectOutlet" type="submit" value={t("Submit")} className="big-button form-control btn" />
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        saveOperatorId: id => dispatch(saveOperatorId(id)),
        fetchTheme: data => dispatch(fetchTheme(data)),
    };
};

export default connect(null, mapDispatchToProps)(CheckOperator);